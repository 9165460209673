const fields = [
  {
    key: 'file',
    type: 'upload',
    templateOptions: {
      label: 'excel'
    }
  }
]

export default fields
