<template>
  <!-- Sign In Block -->
  <div class="block block-themed block-fx-shadow mb-0">
    <div class="block-header">
      <h3 class="block-title">Sign In</h3>
      <div class="block-options">
        <a class="btn-block-option font-size-sm" :href="forgotPasswordUrl">{{$t('forgotPassword')}}</a>
      </div>
    </div>
    <div class="block-content">
      <div class="p-sm-3 px-lg-4 py-lg-5">
        <h1 class="mb-2">League Manager</h1>
        <p>Welcome, please login.</p>

        <!-- Sign In Form -->
        <!-- jQuery Validation (.js-validation-signin class is initialized in js/pages/op_auth_signin.min.js which was auto compiled from _es6/pages/op_auth_signin.js) -->
        <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
        <form @submit.prevent="handleSubmit">
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <span v-if="authenticationErrorCode === 400" class="help-block text-danger d-block mb-2">{{ $t('email-password.notValid') }}</span>
          <button type="submit" class="btn btn-block btn-primary">
            <i v-if="!authenticating" class="fa fa-fw fa-sign-in-alt mr-1"></i> <i v-if="authenticating" class="fa fa-fw fa-cog fa-spin"></i> {{ $t('sign-in') }}
          </button>
        </form>
        <!-- END Sign In Form -->
      </div>
    </div>
  </div>
  <!-- END Sign In Block -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      model: {
        email: '',
        password: ''
      },
      form: {},
      fields: [
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            inputType: 'email',
            label: 'email'
          }
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            inputType: 'password',
            label: 'password'
          }
        }
      ]
    }
  },
  computed: {
    forgotPasswordUrl () {
      return process.env.VUE_APP_OFFICIAL_WEBSITE_URL + '/auth/forgot-password'
    },
    ...mapGetters('auth', [
      'authenticating',
      'authenticationError',
      'authenticationErrorCode'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    handleSubmit () {
      // Perform a simple validation that email and password have been typed in
      if (this.model.email !== '' && this.model.password !== '') {
        this.login({ email: this.model.email, password: this.model.password })
      }
    }
  }
}
</script>
