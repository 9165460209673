export function getI18nDateTimeFormat (isHour12) {
  return {
    time: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: isHour12
    },
    numericDateTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: isHour12
    },
    short: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateLong: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    }
  }
}
