import ApiService from './api.service'

const SettingsService = {
  index () {
    return ApiService.get('api/v1/admin/settings')
  },
  store (data) {
    return ApiService.post('api/v1/admin/settings', data)
  }
}

export default SettingsService
