const fields = [
  {
    key: 'isFriendlyGame',
    type: 'boolean',
    options: [],
    templateOptions: {
      label: 'isFriendlyGame'
    }
  },
  {
    key: 'round',
    type: 'input',
    templateOptions: {
      label: 'round',
      atts: {
        type: 'number'
      }
    }
  },
  {
    key: 'group',
    type: 'ntm-select',
    options: [],
    disabled: false,
    templateOptions: {
      label: 'group'
    }
  },
  {
    key: 'teamA',
    type: 'ntm-select',
    options: [],
    templateOptions: {
      label: 'teamA'
    }
  },
  {
    key: 'teamB',
    type: 'ntm-select',
    options: [],
    templateOptions: {
      label: 'teamB'
    }
  }
]

export default fields
