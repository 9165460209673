import router from '@/router'
import NewsService from '@/services/news.service'
import SponsorService from '@/services/sponsor.service'
export function useCreateNewEntity () {
  function createEmptyNewsItemAndEdit () {
    NewsService.store().then((res) => {
      router.push({ name: 'news.edit', params: { uuid: res.data.uuid } })
    })
  }

  function createEmptySponsorAndEdit () {
    SponsorService.store().then((res) => {
      router.push({ name: 'sponsors.edit', params: { uuid: res.data.uuid } })
    })
  }

  return {
    createEmptyNewsItemAndEdit,
    createEmptySponsorAndEdit
  }
}
