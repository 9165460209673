<template>
  <button @click="downloadPDF()" class="btn btn-sm btn-info"> <i v-if="!loading" class="fa fa-file"></i> <i v-if="loading" class="fa fa-spinner fa-spin"></i></button>
</template>

<script>
import ExportService from '../../services/export.service'

export default {
  name: 'PdfDownload',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    downloadPDF () {
      this.loading = true
      ExportService.schedulePdf().then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${this.filename}.pdf`)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    filename () {
      return this.$d(new Date(), 'dateLong')
    }
  }
}
</script>
