const fields = [
  {
    key: 'name',
    type: 'input',
    templateOptions: {
      label: 'name'
    }
  },
  {
    key: 'startDate',
    type: 'datepicker',
    templateOptions: {
      label: 'startDate',
      onlyDate: true,
      autoClose: true
    }
  },
  {
    key: 'isCurrent',
    type: 'boolean',
    templateOptions: {
      label: 'currentSeasonOnWebsite'
    }
  }
]

export default fields
