<template>
  <div class="venue_index">
    <page-header :title="$tc('venue.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$t('search')" v-model="search">
          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{ $t('action.addNew') }}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="customModel" @pageChange="setPage" url="api/venues" index="venues" :actions="true">
            <template slot="actions" slot-scope="{row}">
              <router-link :to="{ name: 'venues.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                <i class="fa fa-pencil"></i>
              </router-link>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>
<script>
import VenueService from '@/services/venue.service'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import { useExternalLinks } from '@/use/useExternalLinks'

export default {
  mixins: [basicIndexMixin],
  setup () {
    const { getWebsiteDomain } = useExternalLinks()

    return {
      getWebsiteDomain
    }
  },
  data () {
    return {
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'nfcLink',
          prop: 'nfc',
          type: 'clipboard'
        },
        {
          name: 'broadcastWidgetLink',
          prop: 'broadcastWidgetLink',
          type: 'clipboard',
          icon: 'fa fa-video-camera'
        },
        {
          name: 'scoreboardLink',
          prop: 'scoreboardLink',
          type: 'clipboard',
          icon: 'fa fa-television'
        },
        {
          name: 'shotClockLink',
          prop: 'shotClockLink',
          type: 'clipboard',
          icon: 'fa fa-television'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    VenueService.index('').then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    fetchData () {
      VenueService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    },
    createPage () {
      router.push({ name: 'venues.create' })
    }
  },
  computed: {
    customModel () {
      const model = this.model
      const domain = this.getWebsiteDomain()
      if (this.model?.data) {
        this.model.data.map((i) => {
          i.nfc = `${domain}?venue=${i.uuid}`
          i.broadcastWidgetLink = `${domain}/venues/${i.uuid}/broadcast-game`
          i.scoreboardLink = `${domain}/venues/${i.uuid}/scoreboard-game`
          i.shotClockLink = `${domain}/venues/${i.uuid}/shot-clock-game`
          return i
        })
      }

      return model
    }
  }
}
</script>
