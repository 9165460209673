<template>
  <div id="page-loader" class="show">
    <div class="content-loader-inner">
      <div class="spinner" />
      <img :src="LeLogo" :alt="LeLogo">
    </div>
  </div>
</template>

<script>
import LeLogo from '@/assets/le.png'

export default {
  name: 'Loader',
  data () {
    return {
      LeLogo
    }
  }
}
</script>
