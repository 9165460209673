<template>
   <div class="form-group" :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
      <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
      <date-picker v-model="model[field.key]" label="" :locale="$t('locale-datepicker')" :formatted="dateFormat" :output-format="datepickerOptions.outputFormat" :placeholder="to.placeholder" :only-time="to.onlyTime ? to.onlyTime : false" :only-date="to.onlyDate ? to.onlyDate : false" :auto-close="to.autoClose ? to.autoClose : false" :minute-interval="5" :format="dateFormat"></date-picker>
      <span class="help-block form-text text-danger"
            v-if="form.$errors[field.key] && form.$errors[field.key].length > 0"
            v-text="$t('validation.' + field.key + '.' + form.$errors[field.key][0])" />
      <!-- <error-display v-if="form.$errors[field.key].lenght > 0" :form="form" :field="field.key"></error-display> -->
   </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
export default {
  mixins: [baseField],
  data () {
    return {
      date: new Date()
    }
  },
  computed: {
    settings () {
      return this.$store.state.settings
    },
    datepickerOptions () {
      if (this.to.onlyDate) {
        return {
          outputFormat: 'YYYY-MM-DD',
          useCurrent: true
        }
      }

      if (this.to.onlyTime) {
        return {
          outputFormat: 'HH:mm',
          useCurrent: true
        }
      }

      return {
        outputFormat: 'YYYY-MM-DD HH:mm',
        useCurrent: true
      }
    },
    dateFormat () {
      // YYYY-MM-DD h:mm a
      let format = `${this.buildFormatOfDate} ${this.buildFormatOfTime}`

      if (this.to.onlyDate) {
        format = this.buildFormatOfDate
      }

      if (this.to.onlyTime) {
        format = this.buildFormatOfTime
      }

      return format
    },
    buildFormatOfDate () {
      switch (this.settings.formatOfDate) {
        case 'DMY':
          return 'DD-MM-YYYY'
        case 'MDY':
          return 'MM-DD-YYYY'
        case 'YMD':
          return 'YYYY-MM-DD'
        default:
          return 'DD-MM-YYYY'
      }
    },
    buildFormatOfTime () {
      switch (this.settings.formatOfTime) {
        case '12h-clock':
          return 'h:mm a'
        case '24h-clock':
          return 'HH:mm'
        default:
          return 'HH:mm'
      }
    }
  }
  // components: {
  //   datepicker
  // }
}
</script>
