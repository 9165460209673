<template>
  <div class="about">
    <page-header :title="$t('settings.name')"></page-header>
    <page-content>
      <form v-if="!model.isActive" @submit.prevent="onSubmit()">
        <ntm-block title="Connect with the facebook">
          <ol>
            <li>If you don't have a Facebook developer account you have to create it <a href="https://developers.facebook.com/async/registration/">here</a></li>
            <li>Create new facebook app <a href="https://developers.facebook.com/apps/create/">here</a></li>
            <li>Select <strong>Business</strong></li>
            <li>Go to <a href="https://developers.facebook.com/apps/">My apps</a> page and select created application</li>
            <li>From the left menu go to <strong>Settings > Basic</strong> and get <strong>App ID</strong> and <strong>App secret</strong> (add those keys into the form below)</li>
            <li>Go to <a href="https://developers.facebook.com/tools/explorer">Graph API Explorer</a> to generate access token</li>
            <li>Before you generate the token you have to add some permissions which you can find on the right side:
              <ul>
                <li><strong>publish_video</strong></li>
                <li><strong>pages_show_list</strong></li>
                <li><strong>publish_to_groups</strong></li>
                <li><strong>pages_read_engagement</strong></li>
                <li><strong>pages_manage_posts</strong></li>
              </ul>
            </li>
            <li>Click <strong>Generate Access Token</strong> button and copy generated token from input above and fill the form</li>
            <li>Save </li>
          </ol>

          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{ $t('save') }}</button>
        </ntm-block>
      </form>
      <ntm-block v-if="model.isActive" title="Facebook user access token">
        <template #options><button class="btn btn-danger mb-3" @click="deleteFbAccessToken">{{ $t('delete') }}</button></template>
      </ntm-block>
      <ntm-block v-if="model.isActive" :title="$tc('select.page', 1)">
        <formly-form :form="entityForm" :model="model" :fields="entityFields"></formly-form>
        <button class="btn btn-success mt-3 mb-3" @click="saveEntity">{{ $t('save') }}</button>
      </ntm-block>
    </page-content>
  </div>
</template>

<script>
import fields from './formDefinition'
import entityFields from './entityFormDefinition'
import FacebookService from '../../services/facebook.service'

export default {
  data () {
    return {
      model: {
        entity: null
      },
      fields: fields,
      entityFields: entityFields,
      entityForm: {},
      form: {},
      entityOptions: [
        {
          name: 'Pages',
          entities: []
        },
        {
          name: 'Groups',
          entities: []
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    FacebookService.index().then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  mounted () {
    this.getEntities()
  },
  methods: {
    getEntities () {
      FacebookService.getAccounts().then((res) => {
        this.entityFields[0].options = res.data
      })
    },
    fetchData () {
      FacebookService.index().then((response) => {
        this.setData(response.data)
        this.$store.commit('loaded')
      })
    },
    setData (data) {
      this.model = data
    },
    deleteFbAccessToken () {
      FacebookService.deleteAccessToken().then(() => {
        this.fetchData()
      })
    },
    onSubmit () {
      FacebookService.store(this.model).then(() => {
        this.fetchData()
        this.getEntities()
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    },
    saveEntity () {
      FacebookService.saveEntity({ entity: this.model.entity, isAutoGenerateGameEvent: this.model.isAutoGenerateGameEvent }).then(() => {
        this.fetchData()
      })
    }
  }
}
</script>
