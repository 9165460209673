import { useRouteQuery } from '@/use/useRouteQuery'
import _ from 'lodash'

const basicIndexMixin = {
  data () {
    return {
      search: '',
      model: {}
    }
  },
  setup () {
    const page = useRouteQuery('page')
    const type = useRouteQuery('type')

    return {
      page,
      type
    }
  },
  watch: {
    search: 'doSearch'
  },
  methods: {
    doSearch () {
      this.page = 1
      this.$nextTick(() => {
        this.onSearch()
      })
    },
    onSearch: _.debounce(function () {
      this.fetchData()
    }, 200),
    setData (data) {
      this.model = data
    },
    setPage (page) {
      this.page = page
      this.$nextTick(() => {
        this.fetchData()
      })
    }
  }
}

export default basicIndexMixin
