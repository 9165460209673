<template>
  <div class="row">
    <div class="col-sm-8">
      <vue-dropzone @vdropzone-sending="sendingEvent" @vdropzone-success="$emit('upload')" class="mb-3" id="dropzone"
                    :options="dropzoneOptions"></vue-dropzone>
    </div>
    <div v-if="file?.uuid" class="col-sm-4">

      <a :href="file.url" class="btn btn-sm btn-info mb-2 w-100" download>{{file.name}}</a>
      <button type="button" class="btn btn-sm btn-danger w-100" @click="deleteMedia(file)">{{$t('delete')}}</button>
    </div>
  </div>
</template>

<script>
import { TokenService } from '../../services/storage.service'
import vue2Dropzone from 'vue2-dropzone'
import MediaService from '@/services/media.service'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    entityType: {
      type: String,
      default: ''
    },
    entityUuid: {
      type: String,
      default: ''
    },
    collection: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      imageUrl: ''
    }
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    },
    dropzoneOptions () {
      return {
        url: `${process.env.VUE_APP_BASE_URL}api/v1/admin/media/upload`,
        headers: {
          'X-ORGANISATION': this.currentClient.uuid,
          Authorization: `Bearer ${TokenService.getToken()}`,
          Accept: '*'
        }
      }
    }
  },
  methods: {
    deleteMedia (file) {
      MediaService.delete(file.uuid).then(() => {
        this.$emit('delete')
      })
    },
    sendingEvent (file, xhr, formData) {
      formData.append('type', this.collection)
      formData.append('uuid', this.entityUuid)
      formData.append('assetType', this.entityType)
    }
  }
}
</script>
