export function gameStatusColor (status) {
  switch (status) {
    case 'SCHEDULED':
      return 'table-info'
    case 'ARCHIVED':
      return 'table-success'
    case 'FINISHED':
      return 'table-success'
    case 'LIVE':
      return 'table-warning'
  }
}
