import ApiService from './api.service'

const TeamService = {
  show (scheduleUuid, teamUuid) {
    return ApiService.get('api/v1/admin/schedules/' + scheduleUuid + '/teams/' + teamUuid)
  },

  create (scheduleUuid) {
    return ApiService.get('api/v1/admin/schedules/' + scheduleUuid + '/teams/create')
  },

  update (scheduleUuid, teamUuid, data) {
    return ApiService.put('api/v1/admin/schedules/' + scheduleUuid + '/teams/' + teamUuid, data)
  },

  delete (scheduleUuid, teamUuid) {
    return ApiService.delete('api/v1/admin/schedules/' + scheduleUuid + '/teams/' + teamUuid)
  },

  store (scheduleUuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + scheduleUuid + '/teams', data)
  },

  storeWithoutLoading (scheduleUuid, data) {
    return ApiService.postWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/teams', data)
  },

  addPlayerToTeam (scheduleUuid, teamUuid, data) {
    return ApiService.postWithoutLoading(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/addPlayerToTeam`, data)
  },

  addPlayerFromAnotherTeam (scheduleUuid, teamUuid, data) {
    return ApiService.postWithoutLoading(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/addPlayerFromAnotherTeam`, data)
  },

  addPlayerFromAnotherClub (scheduleUuid, teamUuid, data) {
    return ApiService.postWithoutLoading(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/addPlayerFromAnotherClub`, data)
  },

  createAndAddNewPlayerToTeam (scheduleUuid, teamUuid, data) {
    return ApiService.postWithoutLoading(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/createAndAddNewPlayerToTeam`, data)
  },

  deletePlayerFromTeam (scheduleUuid, teamUuid, playerUuid) {
    return ApiService.deleteWithoutLoading(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/players/${playerUuid}`)
  },

  getTeamPlayers (scheduleUuid, teamUuid) {
    return ApiService.get(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/getTeamPlayers`)
  },

  getLatestClubTeams (scheduleUuid, teamUuid) {
    return ApiService.get(`api/v2/admin/schedules/${scheduleUuid}/teams/${teamUuid}/get-latest-club-teams`)
  },

  searchClubPlayers (scheduleUuid, teamUuid, search) {
    return ApiService.get(`api/v1/admin/schedules/${scheduleUuid}/teams/${teamUuid}/searchClubPlayers?search=${search}`)
  },

  destroy (scheduleUuid, teamUuid) {
    return ApiService.delete('api/v1/admin/schedules/' + scheduleUuid + '/teams/' + teamUuid)
  }
}

export default TeamService
