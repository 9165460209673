var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-3"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"file-drop-area"},[_c('div',{staticClass:"file-wrapper mb-3 position-relative"},[(_vm.loading)?_c('div',{staticClass:"file-overlay"},[_vm._m(0)]):_vm._e(),(!_vm.loading)?_c('input',{ref:"file",staticClass:"file-input",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event)}}}):_vm._e(),_c('img',{staticClass:"crop-image",attrs:{"src":_vm.imageUrl || '/no-image.png',"alt":""}})])]),(_vm.imageUrl)?_c('button',{staticClass:"btn btn-danger w-100 mb-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteImage()}}},[_vm._v(_vm._s(_vm.$t('delete'))+" ")]):_vm._e()]),_c('modal',{attrs:{"name":`crop-modal-${_vm._uid}`,"adaptive":true,"height":"auto"}},[(_vm.image.src)?_c('cropper',{ref:"cropper",attrs:{"stencil-component":_vm.circle ? 'circle-stencil' : 'rectangle-stencil',"canvas":{
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
      },"stencil-props":{
        aspectRatio: this.aspectRatio,
      },"src":_vm.image.src}}):_vm._e(),_c('div',{staticClass:"p-3 d-flex crop-actions"},[(_vm.image.src)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.uploadImage}},[_vm._v(" "+_vm._s(_vm.$t('file.cropAndSave'))+" ")]):_vm._e(),(_vm.image.src)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.reset}},[_c('i',{staticClass:"fa fa-undo"})]):_vm._e()])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"upload-cog"},[_c('i',{staticClass:"fa fa-cog fa-spin"})])
}]

export { render, staticRenderFns }