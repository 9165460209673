<template>
  <div >
    <page-header :title="$t('playoff.create')">
      <li class="breadcrumb-item"><router-link :to="{name:'schedules.index'}">{{$tc('schedule.name', 2)}}</router-link></li>
      <li class="breadcrumb-item"><router-link :to="{name:'schedules.show', params: { uuid: model.uuid }}">{{model.name}}</router-link></li>
      <li class="breadcrumb-item">{{$t('playoff.create')}}</li>
    </page-header>
    <page-content>
        <ntm-block class="short-block">
          <formly-form :form="form" :model="formData" :fields="fields"></formly-form>
          <div class="form-group row" v-for="round in rounds" :key="round.uuid">
            <label class="col-sm-6 col-form-label">{{getNameOfRound(round.round)}}</label>
            <div class="col-sm-6">
              <input type="number" class="form-control" v-model="round.bestOf" :placeholder="$t('game.number')">
            </div>
          </div>
          <button @click="editRounds()" v-if="Object.keys(rounds).length > 0" class="btn btn-success push-15">{{$t('save')}}</button>
          <div class="form-group row">
          </div>
        </ntm-block>
    </page-content>
  </div>
</template>

<script>

import fields from './createPlayoffFormDefinition'
import ScheduleService from '../../../services/schedule.service'
import seasonShowMixin from '@/mixins/season.show.mixin'
import store from '@/store'

export default {
  mixins: [seasonShowMixin],
  beforeRouteEnter (to, from, next) {
    ScheduleService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  data () {
    return {
      form: {},
      formData: {
        numberOfTeams: null,
        thirdPlace: false
      },
      rounds: [],
      model: {

      },
      fields: fields
    }
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        if (this.formData.numberOfTeams) {
          this.makePlayoff()
        }
      }
    }
  },
  methods: {
    getNameOfRound (round) {
      switch (round) {
        case 8:
          return this.$t('playoff.round.8')
        case 4:
          return this.$t('playoff.round.4')
        case 2:
          return this.$t('playoff.round.2')
        case 1:
          return this.$t('playoff.round.1')
        default:
          return this.$t('playoff.round.bestOf', { round: round })
      }
    },
    makePlayoff () {
      ScheduleService.makePlayoff(this.$route.params.uuid, {
        bestOf: this.formData.numberOfTeams.uuid,
        thirdPlace: this.formData.thirdPlace,
        isShuffle: this.formData.isShuffle
      }).then((res) => {
        this.rounds = res.data
        store.commit('loaded')
      })
    },
    editRounds () {
      ScheduleService.editRounds(this.$route.params.uuid, {
        rounds: this.rounds
      }).then((res) => {
        this.$router.push({ name: 'schedules.playoff.show', params: { uuid: this.$route.params.uuid } })
      })
    }
  }
}
</script>

<style lang="scss">
  .playoff-wizard {
    padding: 30px;
  }

  .vue-form-wizard.md .wizard-icon-circle {

    &:focus {
      outline: 0;
    }
  }
</style>
