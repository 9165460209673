import Index from '@/views/documents/Index'
import Create from '@/views/documents/Create'
import Edit from '@/views/documents/Edit'

export default [
  {
    path: ':clientId/documents',
    name: 'documents.index',
    component: Index
  },
  {
    path: ':clientId/documents/create',
    name: 'documents.create',
    component: Create,
    meta: {
      title: 'Create document',
      breadcrumb: [
        {
          name: 'Documents',
          route: {
            name: 'documents.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: ':clientId/documents/:uuid',
    name: 'documents.edit',
    component: Edit,
    meta: {
      title: 'Edit document',
      breadcrumb: [
        {
          name: 'Documents',
          route: {
            name: 'documents.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]
