<template>
  <div class="d-inline-block">
    <button v-if="['SCHEDULED'].includes(game.status) && game.embedVideo?.source_type !== 'YOUTUBE'" class="btn btn-sm btn-warning" @click="makeYTGameEvent(game)"><i v-if="!loading" class="fa fa-youtube"></i> <i v-if="loading" class="fa fa-cog fa-spin"></i></button>
    <button v-if="game.embedVideo?.source_type === 'YOUTUBE'" class="btn btn-sm btn-danger" @click="deleteYTGameEvent(game)"><i v-if="!loading" class="fa fa-youtube-play"></i> <i v-if="loading" class="fa fa-cog fa-spin"></i></button>
  </div>
</template>

<script>
import EmbedVideoService from '@/services/embedVideo.service'
import axios from 'axios'
import VueI18n from '@/i18n'
import { loginToGoogleApi } from '@/lib/yt'
import { useExternalLinks } from '@/use/useExternalLinks'

export default {
  props: {
    game: undefined
  },
  data () {
    return {
      loading: false
    }
  },
  setup () {
    const { getGameWebsiteUrl } = useExternalLinks()

    return {
      getGameWebsiteUrl
    }
  },
  watch: {
    size: 'changeSize'
  },
  methods: {
    deleteEmbedVideo (game) {
      EmbedVideoService.deleteWithoutAsking(game.embedVideo.uuid).then(() => {
        this.emitChange()
      })
    },
    deleteYTGameEvent (game) {
      this.loading = true
      axios.delete(`https://www.googleapis.com/youtube/v3/liveBroadcasts?id=${game.embedVideo?.source_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('yt-access-token')}`,
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      }).then(() => {
        this.deleteEmbedVideo(game)
      }).catch((err) => {
        if ([401, 400, 403].includes(err.response?.data?.error?.code)) {
          this.$notify(err.response?.data?.error?.code, 'error')
          loginToGoogleApi(this.deleteYTGameEvent, game)
        }

        if ([404].includes(err.response?.data?.error?.code)) {
          this.deleteEmbedVideo(game)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    makeYTGameEvent (game) {
      this.loading = true
      const currentLocale = VueI18n.locale

      VueI18n.locale = this.$store.state.settings.lang

      const hasBothTeams = game.teamA?.name && game.teamB?.name

      const translate = hasBothTeams ? 'playoff' : 'playoffWithoutTeams'

      const title = game.duelSlug
        ? this.$t(`game.title.${translate}`, {
          homeTeamName: game.teamA?.name,
          awayTeamName: game.teamB?.name,
          playoffRound: (this.getPlayoffRound(game.playoffRound)),
          game: game.round,
          leagueName: game.league.name,
          seasonName: game.season.name
        })
        : this.$t('game.title.league', {
          homeTeamName: game.teamA.name,
          awayTeamName: game.teamB.name,
          round: game.round,
          leagueName: game.league.name,
          seasonName: game.season.name
        })

      const accessToken = localStorage.getItem('yt-access-token')

      if (accessToken) {
        const reqBody = {
          snippet: {
            title: title,
            scheduledStartTime: game.utcTime

          },
          contentDetails: {
            enableEmbed: true,
            enableAutoStart: true
          },
          status: { privacyStatus: 'public' }
        }

        if (hasBothTeams) {
          reqBody.snippet.description = this.$t('youtube.scheduled-livestream.description', {
            gameUrl: this.getGameWebsiteUrl(game)
          })
        }

        VueI18n.locale = currentLocale

        axios.post('https://content-youtube.googleapis.com/youtube/v3/liveBroadcasts?part=id&part=snippet&part=contentDetails&part=status&alt=json', reqBody, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-type': 'application/json'
          }
        }).then((res) => {
          this.createYTEmbedVideo(game, res.data)
        }).catch((err) => {
          if ([400, 401, 403].includes(err.response?.data?.error?.code)) {
            this.$notify(err.response?.data?.error?.code, 'error')
            loginToGoogleApi(this.makeYTGameEvent, game)
          }
        }).finally(() => {
          this.loading = false
        })
      } else {
        loginToGoogleApi(this.makeYTGameEvent, game)
      }
    },
    createYTEmbedVideo (game, ytResData) {
      EmbedVideoService.store({
        code: ytResData.contentDetails.monitorStream.embedHtml.replace('autoplay=1&', '').replace('width="425"', 'width="560"').replace('height="344"', 'height="315"').replace('livemonitor=1', ''),
        source_type: 'YOUTUBE',
        source_id: ytResData.id,
        entity_uuid: game.uuid,
        entity_type: 'Game'
      }).then(() => {
        this.emitChange()
      })
    },
    getPlayoffRound (playoffRound) {
      if (!playoffRound) {
        return
      }

      if (playoffRound > 8) {
        return this.$t('playoff.round.bestOf', { round: playoffRound })
      } else {
        return this.$t(`playoff.round.${playoffRound}`)
      }
    },
    emitChange () {
      this.$emit('changed')
    }
  }
}
</script>
<style>
 .qr-button {
   display: block;
   border: 0;
   background: none;
   cursor: pointer;
 }
</style>
