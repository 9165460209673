const fields = [
  {
    key: 'gameUuid',
    type: 'input',
    templateOptions: {
      label: 'gameUuid'
    }
  }
]

export default fields
