export default [
  {
    key: 'appId',
    type: 'input',
    templateOptions: {
      label: 'appId'
    }
  },
  {
    key: 'appSecret',
    type: 'input',
    templateOptions: {
      label: 'appSecret'
    }
  },
  {
    key: 'accessToken',
    type: 'textarea',
    templateOptions: {
      attrs: {
        rows: 5
      },
      label: 'accessToken'
    }
  }
]
