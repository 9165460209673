const fields = [
  {
    key: 'title',
    type: 'input',
    templateOptions: {
      label: 'title'
    }
  },
  {
    key: 'shortDescription',
    type: 'textarea',
    templateOptions: {
      label: 'shortDescription'
    }
  },
  {
    key: 'publishDate',
    type: 'datepicker',
    templateOptions: {
      label: 'publishDate'
    }
  },
  {
    key: 'tags',
    type: 'ntm-select',
    isString: true,
    taggable: true,
    multiple: true,
    optionsApi: 'tags',
    templateOptions: {
      label: 'tags'
    }
  },
  {
    key: 'content',
    type: 'html',
    templateOptions: {
      label: 'content',
      uuid: null,
      assetType: null
    }
  }
]

export default fields
