<template>
  <button v-if="game.status === 'ARCHIVED'" @click="download(game.uuid)" class="btn btn-sm btn-primary"><i v-if="loading" class="fa fa-spinner fa-spin"></i> <i class="fa fa-file-excel-o" v-if="!loading"></i></button>
</template>

<script>
import ExportService from '../../services/export.service'

export default {
  name: 'XlsGameDownload',
  data () {
    return {
      loading: false
    }
  },
  props: {
    game: {
      type: Object
    },
    league: {
      type: Object
    },
    round: {
      type: String
    }
  },
  methods: {
    download () {
      this.loading = true
      ExportService.gameXls(this.game.uuid).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${this.filename}.xlsx`)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    filename () {
      return `${this.league.season.name}==${this.league.name}==${this.round}==${this.game.teamA.name} vs ${this.game.teamB.name}`
    }
  }
}
</script>
