import ApiService from './api.service'

const MemberService = {
  index (page = 1) {
    return ApiService.get(`api/v2/admin/members?page=${page}`)
  },
  delete (uuid) {
    return ApiService.deleteWithoutLoading(`api/v2/admin/members/${uuid}`)
  }
}

export default MemberService
