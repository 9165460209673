import Index from '@/views/debts/Index'
import Create from '@/views/debts/Create'
import Edit from '@/views/debts/Edit'

export default [
  {
    path: ':clientId/debts',
    name: 'debts.index',
    component: Index
  },
  {
    path: ':clientId/debts/create',
    name: 'debts.create',
    component: Create,
    meta: {
      title: 'Create debt',
      breadcrumb: [
        {
          name: 'Debts',
          route: {
            name: 'debts.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: ':clientId/debts/:uuid',
    name: 'debts.edit',
    component: Edit,
    meta: {
      title: 'Edit debt',
      breadcrumb: [
        {
          name: 'Debts',
          route: {
            name: 'debts.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]
