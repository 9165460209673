import ApiService from './api.service'
import axios from 'axios'

const ExportService = {
  playerTemplate () {
    return ApiService.postWithoutLoading('api/v2/admin/export/playerTemplate')
  },
  clubTemplate () {
    return ApiService.postWithoutLoading('api/v2/admin/export/clubTemplate')
  },
  players () {
    return ApiService.postWithoutLoading('api/v1/admin/export/players')
  },
  clubs () {
    return ApiService.postWithoutLoading('api/v1/admin/export/clubs')
  },
  gamePdf (gameUuid) {
    return axios({
      url: process.env.VUE_APP_BASE_URL + 'api/v1/export/game/' + gameUuid + '/pdf',
      method: 'GET',
      responseType: 'blob'
    })
  },
  gameXls (gameUuid) {
    return ApiService.postBlob('api/v2/admin/export/game/' + gameUuid + '/xlsx')
    // return axios({
    //   url: process.env.VUE_APP_BASE_URL + 'api/v2/admin/export/game/' + gameUuid + '/xlsx',
    //   method: 'POST',
    //   responseType: 'blob'
    // })
  },
  schedulePdf () {
    return ApiService.postBlob('api/v1/export/schedule')
  },
  teamPlayersImagesDownload (schedule, team) {
    return ApiService.postBlob(`api/v1/admin/schedules/${schedule}/teams/${team}/download-player-images`)
  }
}

export default ExportService
