import ApiService from './api.service'

const VenueService = {
  index (search = '', page = 1) {
    return ApiService.get(`api/v1/admin/venues?search=${search}&page=${page}`)
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/venues/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v1/admin/venues/' + uuid, data)
  },

  store (data) {
    return ApiService.post('api/v1/admin/venues', data)
  },

  addStream (uuid, data) {
    return ApiService.post('api/v1/admin/venues/' + uuid + '/streams', data)
  },

  deleteStream (uuid, streamUuid, data) {
    return ApiService.delete('api/v1/admin/venues/' + uuid + '/streams/' + streamUuid, data)
  }
}

export default VenueService
