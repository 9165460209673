<template>
  <div class="about">
    <page-header :title="$tc('import.name', 1)">

    </page-header>
    <page-content>
        <ntm-block :title="$tc('player.name', 2)">
          <template #options>
            <button @click="downloadPlayerTemplate()" type="button"  class="btn btn-small btn-warning">
              <i class="fa fa-download"></i>
              {{ $tc('template', 1) }}
            </button>
          </template>
          <p v-html="$tc('import.player.desc')"></p>
          <vue-dropzone :useCustomSlot="true" ref="myVueDropzone" class="mb-3" id="dropzone" :options="dropzonePlayerOptions">
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">{{ $tc('import.player.window') }}</h3>
              <div class="subtitle">{{ $tc('import.orSelectFromPC') }}</div>
            </div>
          </vue-dropzone>
        </ntm-block>

      <ntm-block :title="$tc('club.name', 2)">
        <template #options>
          <button @click="downloadClubTemplate()" type="button"  class="btn btn-small btn-warning">
            <i class="fa fa-download"></i>
            {{ $tc('template', 1) }}
          </button>
        </template>
        <p v-html="$tc('import.player.desc')"></p>
        <vue-dropzone :useCustomSlot="true" ref="myVueDropzone" class="mb-3" id="dropzone" :options="dropzoneClubOptions">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{ $tc('import.club.window') }}</h3>
            <div class="subtitle">{{ $tc('import.orSelectFromPC') }}</div>
          </div>
        </vue-dropzone>
      </ntm-block>
    </page-content>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import fields from './formDefinition'
import { TokenService } from '@/services/storage.service'
import ExportService from '../../services/export.service'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      model: {},
      form: {},
      fields: fields
    }
  },
  computed: {
    dropzonePlayerOptions () {
      return {
        url: `${process.env.VUE_APP_BASE_URL}api/v2/admin/import/players`,
        headers: {
          'X-ORGANISATION': this.currentClient.uuid,
          Authorization: `Bearer ${TokenService.getToken()}`
        },
        acceptedFiles: 'text/csv'
      }
    },
    dropzoneClubOptions () {
      return {
        url: `${process.env.VUE_APP_BASE_URL}api/v2/admin/import/clubs`,
        headers: {
          'X-ORGANISATION': this.currentClient.uuid,
          Authorization: `Bearer ${TokenService.getToken()}`
        },
        acceptedFiles: 'text/csv'
      }
    },
    currentClient () {
      return this.$store.state.currentClient
    }
  },
  methods: {
    downloadPlayerTemplate () {
      ExportService.playerTemplate().then(res => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'players.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    downloadClubTemplate () {
      ExportService.clubTemplate().then(res => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'clubs.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  }
}
</script>
