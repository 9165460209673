const fields = [
  {
    key: 'name',
    type: 'input',
    templateOptions: {
      label: 'name'
    }
  },
  {
    key: 'show_time',
    type: 'boolean',
    templateOptions: {
      label: 'show_time'
    }
  },
  {
    key: 'show_shot_clock',
    type: 'boolean',
    templateOptions: {
      label: 'show_shot_clock'
    }
  }
]

export default fields
