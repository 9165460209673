<template>
  <div class="add-team-form">
    <button v-if="accordion === false" @click="accordion = true" class="btn btn-sm btn-info mb-3 w-100"><span>{{$t('schedule.team.add')}}</span></button>
    <button v-if="accordion === true" @click="accordion = false" class="btn btn-sm btn-warning mb-3 w-100"><span>{{$t('hide-form')}}</span></button>
    <form :id="`add-team-group-form-${groupUuid}`" v-if="accordion" @submit.prevent="onAddTeamSubmit()">
        <formly-form :form="form" :model="model" :fields="fields"></formly-form>
        <button class="btn btn-success push-15"><i v-if="loading" class="fa fa-cog fa-spin"></i> <span v-if="!loading">{{$t('save')}}</span></button>
    </form>
    <div class="divider"></div>
  </div>
</template>

<script>

import fields from './createTeamShortFormDefinition'
import TeamService from '@/services/team.service'

export default {
  props: {
    scheduleUuid: String,
    groupUuid: String,
    initOpen: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.accordion = this.initOpen
  },
  data () {
    return {
      accordion: false,
      loading: false,
      form: {},
      model: {},
      fields: fields
    }
  },
  methods: {
    focusClubSearch () {
      setTimeout(() => {
        const clubSelect = document.getElementById(`add-team-group-form-${this.groupUuid}`).getElementsByClassName('multiselect')[0]
        clubSelect.focus()
      })
    },
    onAddTeamSubmit () {
      this.loading = true
      this.form.$errors = []
      TeamService.storeWithoutLoading(this.scheduleUuid, { ...this.model, group: this.groupUuid }).then(() => {
        this.focusClubSearch()

        this.model = {
          club: null,
          out_of_competition: false,
          suffix: null
        }

        this.$emit('teamAdded')
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      }).finally(() => {
        this.loading = false
      })
    },
    clubChanged (value) {
      if (value) {
        const suffixInput = document.getElementById(`add-team-group-form-${this.groupUuid}`).getElementsByClassName('suffix-input')[0]
        suffixInput.focus()
      }
    }
  },
  watch: {
    'model.club': 'clubChanged'
  }
}
</script>
