<template>
  <div class="table-responsive">
    <table class="table table-vcenter table-striped">
      <thead>
      <tr>
        <th>{{ $t('code') }}</th>
        <th>{{ $t('time') }}</th>
        <th v-if="groups.length > 1" class="text-center" style="width: 50px;">{{ $tc('group.name', 1) }}
        </th>
        <th class="text-right" style="min-width: 300px">{{ $t('home') }}</th>
        <th style="min-width: 85px" class="no-wrap"></th>
        <th class="text-left" style="min-width: 300px">{{ $t('away') }}</th>
        <th class="text-center" style="width: 100px;">{{ $tc('action.name', 2) }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="game in games" :key="game.uuid" :class="gameStatusColor(game.status)">
        <td>
          <div class="btn-group">
            <button class="btn btn-info btn-sm"
                    v-if="['NOT_PLAYED', 'SCHEDULED', 'FINISHED'].includes(game.status) && game.teamA && game.teamB"
                    @click="$emit('scheduleGameModal', game)"><i class="fa fa-clock-o"></i></button>
            <button class="btn btn-warning btn-sm"
                    v-if="['SCHEDULED'].includes(game.status) && game.teamA && game.teamB"
                    @click="$emit('cancelScheduledGame', game)"><i class="fa fa-times"></i></button>
          </div>
          <span class="ml-2">{{ game.code }}</span>
        </td>
        <td><strong style="font-size: 12px;">{{ formatDate(game.time) }}</strong></td>
        <td v-if="groups.length > 1" class="text-center" v-text="game.group.name"></td>
        <td class="font-w600 font-size-sm text-right">
          <span>
            <router-link v-if="game.teamA" v-html="teamName(game.teamA)"
                         :to="{ name: 'schedules.teams.show', params: {uuid: $route.params.uuid, team: game.teamA.uuid }}"
                         class="btn btn-sm btn-primary">
            </router-link>
            <span v-else style="font-size: 14px;"
                  v-html="teamName(game.teamA)">
            </span>
            <span v-if="game.teamA">
              <i v-if="game.winner_team_id === game.teamA.id"
                 class="badge badge-success ml-2 p-2">{{ $t("game.w") }}</i>
            </span>
            <span v-if="game.teamB">
                <i v-if="game.winner_team_id === game.teamB.id"
                   class="badge badge-danger ml-2 p-2">{{ $t("game.l") }}</i>
            </span>
          </span>

        </td>
        <td class="font-w600 font-size-sm text-center"><span>{{
            game.home_team_score_total
          }} : {{ game.away_team_score_total }}</span>
        </td>
        <td class="font-w600 font-size-sm">
          <span>
            <span v-if="game.teamA">
              <i v-if="game.winner_team_id === game.teamA.id"
                 class="badge badge-danger mr-2 p-2">{{ $t("game.l") }}</i>
            </span>
            <span v-if="game.teamB">
              <i v-if="game.winner_team_id === game.teamB.id"
                 class="badge badge-success mr-2 p-2">{{ $t("game.w") }}</i>
            </span>
            <router-link v-if="game.teamB" v-html="teamName(game.teamB)"
                         :to="{ name: 'schedules.teams.show', params: {uuid: $route.params.uuid, team: game.teamB.uuid }}"
                         class="btn btn-sm btn-primary">
            </router-link>
            <span v-else class="badge badge-warning" style="font-size: 14px;"
                  v-html="teamName(game.teamB)">
            </span>
          </span>
        </td>
        <td class="text-center">
          <div class="btn-group">
            <template v-if="game.teamA && game.teamB">
              <router-link
                :to="{ name: 'schedules.games.show', params: {uuid: $route.params.uuid, game: game.uuid }}"
                class="btn btn-sm btn-primary">
                <i class="fa fa-pencil"></i>
              </router-link>
              <pdf-game-download :game="game" :round="roundName" :league="league"></pdf-game-download>
              <xlsx-game-download :game="game" :round="roundName"
                                  :league="league"></xlsx-game-download>
            </template>
            <button v-if="['NOT_PLAYED', 'SCHEDULED'].includes(game.status)" @click="swapGameTimeVenue(game)" :class="{'btn-success': swapGame?.uuid === game.uuid, 'btn-info': swapGame?.uuid !== game.uuid}" class="btn btn-sm"><i class="fa fa-arrows-v"></i></button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import ApiService from '@/services/api.service'

import XlsxGameDownload from '@/components/shared/XlsxGameDownload.vue'
import PdfGameDownload from '@/components/shared/PdfGameDownload.vue'
import { gameStatusColor } from '@/lib/status'
import { useGameSwap } from '@/use/useGameSwap'

export default {
  name: 'LeGamesTable',
  components: { PdfGameDownload, XlsxGameDownload },
  props: {
    league: {
      type: Object,
      default: () => ({})
    },
    roundName: {
      type: String,
      default: ''
    },
    games: {
      type: Array,
      default: () => ([])
    },
    groups: {
      type: Array,
      default: () => ([])
    }
  },
  setup () {
    const { swapGame, swapGameTimeAndVenue } = useGameSwap()

    return { swapGame, swapGameTimeAndVenue }
  },
  data () {
    return {
      search: ''
    }
  },
  mounted () {
    // this.fetchData()
  },
  watch: {
  },
  methods: {
    async swapGameTimeVenue (game) {
      await this.swapGameTimeAndVenue({ ...game, league: { name: this.league.name } })
      this.$emit('swapGameTimeVenue')
    },
    formatDate (time) {
      if (!time) {
        return ''
      }

      const date = new Date(time.replace(/-/g, '/'))

      return `${this.$d(date, 'numericDateTime')}`
    },
    gameStatusColor,
    teamName (team) {
      if (!team) {
        return ''
      }

      if (team?.name) {
        return team.name
      }

      return this.$t('emptySlot')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
li.disabled a {
  pointer-events: none;
}
</style>
