import ApiService from './api.service'

const DocumentService = {
  index (search = '', page = 1) {
    return ApiService.get('api/v2/admin/documents')
  },

  show (uuid) {
    return ApiService.get('api/v2/admin/documents/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v2/admin/documents/' + uuid, data)
  },

  store (data) {
    return ApiService.post('api/v2/admin/documents', data)
  },
  delete (uuid) {
    return ApiService.delete('api/v2/admin/documents/' + uuid)
  }
}

export default DocumentService
