<template>
  <div class="player_index">
    <page-header :title="$t('player.merge')">
    </page-header>
    <page-content>
      <div class="row">
        <div class="col-sm-6">
          <ntm-block :title="$t('original')" class="pb-4">
            <v-select @search="(search, loading) => searchPlayer(search, loading, 'original')"
                      :filter="(options) => options"
                      v-model="selectedPlayer.original" :options="playerFilteredOriginalOptions"
                      inputId="playerOriginalSearch" label="name"
                      :placeholder="$tc('player.searchAndAdd')">
              <template #option="option">
                {{ option.name }} - {{option.birthYear}}
              </template>
              <template #no-options>
                <p class="info">{{ $tc('player.searchAndAdd') }}</p>
              </template>
            </v-select>
          </ntm-block>
          <ntm-block v-if="selectedPlayer.original">
            <div class="text-center" style="margin-bottom: 20px">
              <h3>{{ selectedPlayer.original.name }}</h3>
              <h5>{{ selectedPlayer.original.birthYear }}</h5>
              <img v-if="selectedPlayer.original.profile" :src="selectedPlayer.original.profile" alt="">
              <div v-if="!selectedPlayer.original.profile" class="d-flex justify-content-center align-items-center">
                <div class="bg-body-light d-flex justify-content-center align-items-center"
                     style="width: 100px;height: 100px;">
                  <div>{{ $t('noImage') }}</div>
                </div>
              </div>
            </div>
          </ntm-block>
          <ntm-block v-else>
            <div class="text-center" style="margin-bottom: 20px">
              <h3>{{ $tc('select.player', 1) }}</h3>
              <h5>{{ $t('formly.fields.birthYear') }}</h5>
              <div class="d-flex justify-content-center align-items-center">
                <div class="bg-body-light d-flex justify-content-center align-items-center"
                     style="width: 100px;height: 100px;">
                  <div>{{ $t('noImage') }}</div>
                </div>
              </div>
            </div>
          </ntm-block>
        </div>
        <div class="col-sm-6">
          <ntm-block :title="$t('copy')" class="pb-4">
            <v-select @search="(search, loading) => searchPlayer(search, loading, 'copy')"
                      :filter="(options) => options"
                      v-model="selectedPlayer.copy" :options="playerFilteredCopyOptions" inputId="playerSearch"
                      label="name"
                      :placeholder="$tc('player.searchAndAdd')">
              <template #option="option">
                {{ option.name }} - {{option.birthYear}}
              </template>
              <template #no-options>
                <p class="info">{{ $tc('player.searchAndAdd') }}</p>
              </template>
            </v-select>
          </ntm-block>
          <ntm-block v-if="selectedPlayer.copy">
            <div class="text-center" style="margin-bottom: 20px">
              <h3>{{ selectedPlayer.copy.name }}</h3>
              <h5>{{ selectedPlayer.copy.birthYear }}</h5>
              <img v-if="selectedPlayer.copy.profile" :src="selectedPlayer.copy.profile" alt="">
              <div v-if="!selectedPlayer.copy.profile" class="d-flex justify-content-center align-items-center">
                <div class="bg-body-light d-flex justify-content-center align-items-center"
                     style="width: 100px;height: 100px;">
                  <div>{{ $t('noImage') }}</div>
                </div>
              </div>
            </div>
          </ntm-block>
          <ntm-block v-else>
            <div class="text-center" style="margin-bottom: 20px">
              <h3>{{ $tc('select.player', 1) }}</h3>
              <h5>{{ $t('formly.fields.birthYear') }}</h5>
              <div class="d-flex justify-content-center align-items-center">
                <div class="bg-body-light d-flex justify-content-center align-items-center"
                     style="width: 100px;height: 100px;">
                  <div>{{ $t('noImage') }}</div>
                </div>
              </div>
            </div>
          </ntm-block>
        </div>
        <div v-if="selectedPlayer.original && selectedPlayer.copy" class="col-sm-12 text-center">
          <button :disabled="loading" @click="mergePlayers()" class="btn btn-warning"><i v-if="loading" class="fa fa-cog fa-spin"></i> {{ $t('player.merge') }}</button>
        </div>
      </div>
    </page-content>
  </div>
</template>
<script>
import basicIndexMixin from '@/mixins/basic.index.mixin'
import _ from 'lodash'
import PlayerService from '@/services/player.service'
import VueI18n from '@/i18n'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      loading: false,
      playerOptions: {
        original: [],
        copy: []
      },
      selectedPlayer: {
        original: null,
        copy: null
      }
    }
  },
  methods: {
    searchPlayer (search, loading, type) {
      if (search.length > 0) {
        loading(true)
        this.searchPlayers(search, loading, type)
      } else {
        this.playerOptions[type] = []
      }
    },
    searchPlayers: _.debounce(function (search, loading, type) {
      PlayerService.index(search).then((res) => {
        this.playerOptions[type] = res.data.data
        loading(false)
      })
    }, 300),
    mergePlayers () {
      return this.$swal.fire({
        title: VueI18n.t('swal.mergePlayers.title'),
        text: VueI18n.t('swal.mergePlayers.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: VueI18n.t('swal.mergePlayers.yes'),
        cancelButtonText: VueI18n.t('swal.mergePlayers.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          this.loading = true
          return PlayerService.merge(this.selectedPlayer).then((res) => {
            this.selectedPlayer.copy = null
            this.$notify(res.data, 'success')
            this.$store.commit('loaded')
            this.loading = false
          })
        }
      })
    }
  },
  computed: {
    playerFilteredOriginalOptions () {
      return this.playerOptions.original.filter((player) => {
        if (!this.selectedPlayer.copy) return true

        return player.uuid !== this.selectedPlayer.copy.uuid
      })
    },
    playerFilteredCopyOptions () {
      return this.playerOptions.copy.filter((player) => {
        if (!this.selectedPlayer.original) return true

        return player.uuid !== this.selectedPlayer.original.uuid
      })
    }
  }
}
</script>
