import ApiService from './api.service'

const TimelineService = {
  getCurrent () {
    return ApiService.get('api/v1/admin/dashboard/timeline')
  },
  publish (timelineId) {
    return ApiService.postWithoutLoading(`api/v1/admin/dashboard/timeline/${timelineId}/publish`)
  }
}

export default TimelineService
