const fields = [
  {
    key: 'numberOfTeams',
    type: 'ntm-select',
    options: [
      {
        name: '4',
        uuid: 4
      },
      {
        name: '8',
        uuid: 8
      },
      {
        name: '16',
        uuid: 16
      },
      {
        name: '32',
        uuid: 32
      }
    ],
    templateOptions: {
      label: 'numberOfTeams',
      placeholder: 'Select league'
    }
  },
  {
    key: 'thirdPlace',
    type: 'boolean',
    templateOptions: {
      label: 'third_place'
    }
  },
  {
    key: 'isShuffle',
    type: 'boolean',
    templateOptions: {
      label: 'randomDuels'
    }
  }
]

export default fields
