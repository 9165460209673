<template>
  <div class="about">
    <page-header :title="$tc('imageRequest.name', 1)" :desc="model.name">
      <li class="breadcrumb-item"><router-link :to="{name:'imageRequests.index'}">{{ $tc('imageRequest.name', 2) }}</router-link></li>
      <li class="breadcrumb-item">{{model.name}}</li>
    </page-header>
    <page-content>
      <div class="row">
        <div class="col-md-6">
          <ntm-block>
            <div class="text-center" style="margin-bottom: 20px">
              <h3>{{$t('imageRequest.current')}}</h3>
              <img v-if="model.profile" :src="model.profile" alt="">
              <div v-if="!model.profile" class="bg-body-light" style="height: 300px; width: 300px; background: red; margin: auto"><h4 style="padding-top: 120px">{{$t('noImage')}}</h4></div>
            </div>
          </ntm-block>
        </div>
        <div class="col-md-6">
          <ntm-block>
            <div class="text-center" style="margin-bottom: 20px">
              <h3>{{$t('imageRequest.requested')}}</h3>
              <img :src="model.image" alt="">
            </div>
          </ntm-block>
        </div>
        <div class="col-md-12">
          <ntm-block>
            <div class="text-center" style="margin-bottom: 20px">
              <button class="btn btn-success" @click="accept" style="margin-right: 20px"><i class="fa fa-check"></i></button>
              <button class="btn btn-danger" @click="decline"><i class="fa fa-times"></i></button>
            </div>
          </ntm-block>
        </div>
      </div>
    </page-content>
  </div>
</template>

<script>
import ImageRequestService from '@/services/imageRequests.service'
import router from '@/router'
import { useImageRequest } from '@/use/useImageRequest'

export default {
  data () {
    return {
      model: {}
    }
  },
  setup () {
    const { fetchImageRequestsCount } = useImageRequest()
    return { fetchImageRequestsCount }
  },
  beforeRouteEnter (to, from, next) {
    ImageRequestService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    setData (data) {
      this.model = data
    },
    accept () {
      ImageRequestService.accept(this.model.uuid).then(() => {
        this.fetchImageRequestsCount()
        router.push({ name: 'imageRequests.index' })
      })
    },
    decline () {
      ImageRequestService.decline(this.model.uuid).then(() => {
        this.fetchImageRequestsCount()
        router.push({ name: 'imageRequests.index' })
      })
    }
  }
}
</script>
