const fields = [
  {
    key: 'cycles',
    type: 'input',
    templateOptions: {
      label: 'numberOfCycles',
      attrs: {
        type: 'number'
      }
    }
  }
]

export default fields
