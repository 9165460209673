<template>
  <div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  async beforeMount () {
    await router.push({
      name: 'dashboard',
      params: { clientId: this.currentClient.uuid }
    })
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    }
  }
}
</script>
