import Index from '@/views/sponsors/Index'
import Create from '@/views/sponsors/Create'
import Edit from '@/views/sponsors/Edit'

export default [
  {
    path: ':clientId/sponsors',
    name: 'sponsors.index',
    component: Index
  },
  {
    path: ':clientId/sponsors/create',
    name: 'sponsors.create',
    component: Create,
    meta: {
      title: 'Create sponsor',
      breadcrumb: [
        {
          name: 'Sponsors',
          route: {
            name: 'sponsors.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: ':clientId/sponsors/:uuid',
    name: 'sponsors.edit',
    component: Edit,
    meta: {
      title: 'Edit sponsor',
      breadcrumb: [
        {
          name: 'Sponsors',
          route: {
            name: 'sponsors.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]
