import ApiService from './api.service'

const FacebookService = {
  index () {
    return ApiService.get('api/v1/admin/facebook')
  },
  store (data) {
    return ApiService.post('api/v1/admin/facebook', data)
  },
  saveEntity (data) {
    return ApiService.post('api/v1/admin/facebook/entity', data)
  },
  getAccounts (data) {
    return ApiService.get('api/v1/admin/facebook/accounts')
  },
  deleteAccessToken () {
    return ApiService.delete('api/v1/admin/facebook/deleteAccessToken')
  }
}

export default FacebookService
