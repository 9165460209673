<template>
  <div v-if="game.embedVideo?.source_type !== 'YOUTUBE'" class="d-inline-block">
    <button class="btn btn-sm btn-info" @click="openEditModal()"><i v-if="!loading" class="fa fa-code"></i> <i v-if="loading" class="fa fa-cog fa-spin"></i></button>
    <modal :name="`embed-video-${_uid}`" :adaptive="true" height="auto">
      <form @submit.prevent="submitCode()">
        <ntm-block>
          <formly-form :form="codeForm" :model="model" :fields="codeFields"></formly-form>
          <div class="d-flex mb-2 justify-content-center">
            <button class="btn btn-success push-15 mr-2" :disabled="loading"><span
              v-if="!loading">{{ $t('save') }}</span><i v-if="loading"
                                                        class="fa fa-cog fa-spin"></i></button>
            <button type="button" @click="deleteEmbedVideo()" class="btn btn-danger push-15" :disabled="deleteLoading"><span
              v-if="!deleteLoading">{{ $t('delete') }}</span><i v-if="deleteLoading"
                                                          class="fa fa-cog fa-spin"></i></button>
          </div>
        </ntm-block>

      </form>
    </modal>
  </div>
</template>

<script>
import EmbedVideoService from '@/services/embedVideo.service'
import { useExternalLinks } from '@/use/useExternalLinks'

export default {
  props: {
    game: undefined
  },
  data () {
    return {
      model: {
        code: ''
      },
      codeForm: {},
      codeFields: [
        {
          key: 'code',
          type: 'textarea',
          templateOptions: {
            attrs: {
              rows: 5
            },
            label: 'embedVideoCode'
          }
        }
      ],
      deleteLoading: false,
      loading: false
    }
  },
  setup () {
    const { getGameWebsiteUrl } = useExternalLinks()

    return {
      getGameWebsiteUrl
    }
  },
  watch: {
    size: 'changeSize'
  },
  methods: {
    openEditModal () {
      this.model.code = this.game.embedVideo?.code
      this.$modal.show(`embed-video-${this._uid}`)
    },
    submitCode () {
      this.createYTEmbedVideo()
    },
    deleteEmbedVideo () {
      this.deleteLoading = true
      EmbedVideoService.deleteWithoutAsking(this.game.embedVideo.uuid).then(() => {
        this.emitChange()
        this.closeModal()
      }).finally(() => {
        this.deleteLoading = false
      })
    },
    createYTEmbedVideo () {
      this.loading = true
      EmbedVideoService.store({
        code: this.model.code,
        entity_uuid: this.game.uuid,
        entity_type: 'Game'
      }).then(() => {
        this.emitChange()
        this.closeModal()
      }).finally(() => {
        this.loading = false
      })
    },
    closeModal () {
      this.$modal.hide(`embed-video-${this._uid}`)
      this.model.code = ''
    },
    getPlayoffRound (playoffRound) {
      if (!playoffRound) {
        return
      }

      if (playoffRound > 8) {
        return this.$t('playoff.round.bestOf', { round: playoffRound })
      } else {
        return this.$t(`playoff.round.${playoffRound}`)
      }
    },
    emitChange () {
      this.$emit('changed')
    }
  }
}
</script>
<style>
 .qr-button {
   display: block;
   border: 0;
   background: none;
   cursor: pointer;
 }
</style>
