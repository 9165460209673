import { ref } from 'vue'
import DashboardService from '@/services/dashboard.service'
import { useTimeline } from '@/use/useTimeline'

const swapGame = ref(null)
export function useGameSwap (swapCallback = () => {}) {
  const { fetchCurrentTimeline } = useTimeline()
  async function swapGameTimeAndVenue (game) {
    console.log(game)
    console.log(swapGame.value)
    if (swapGame.value) {
      if (swapGame.value.uuid === game.uuid) {
        swapGame.value = null
        return
      }
      await DashboardService.swapGameTimeAndVenue(swapGame.value?.uuid, game.uuid).then(async () => {
        swapCallback()
        fetchCurrentTimeline()
      }).finally(() => {
        swapGame.value = null
      })
    } else {
      swapGame.value = game
    }
  }

  return {
    swapGame,
    swapGameTimeAndVenue
  }
}
