<template>
  <div class="about">
    <page-header :title="$t('document.edit')" :desc="model.name">
      <li class="breadcrumb-item"><router-link :to="{name:'documents.index'}">{{$tc('document.name', 2)}}</router-link></li>
      <li class="breadcrumb-item">{{model.name}}</li>
    </page-header>
    <page-content>
      <form @submit.prevent="onSubmit()">
        <ntm-block :title="$tc('image.name', 1)" :init-collapse="false">
          <ntm-crop entity-type="document" :aspect-ratio="16/10" :max-width="640" :max-height="400" :entity-uuid="$route.params.uuid" conversion="lg" collection="preview"></ntm-crop>
        </ntm-block>
        <ntm-block :title="$tc('document.name', 1)">
          <ntm-upload entity-type="document" :entity-uuid="$route.params.uuid" collection="download" :file="model.file" @delete="fetchFile" @upload="fetchFile"></ntm-upload>
        </ntm-block>
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{$t('save')}}</button>
        </ntm-block>
      </form>
      <ntm-block :title="$tc('action.name', 2)">
        <template slot="options">
          <button type="button"
                  @click="deleteDocument(model.uuid)"
                  class="btn btn-sm btn-danger">{{ $t('document.delete') }}
          </button>
        </template>
      </ntm-block>
    </page-content>
  </div>
</template>

<script>
import fields from './formDefinition'
import DocumentService from '@/services/document.service'
import router from '@/router'
import store from '@/store'

export default {
  // required properties, field, form, model
  data () {
    return {
      form: {},
      model: {},
      fields: fields,
      uploading: false
    }
  },
  beforeRouteEnter (to, from, next) {
    DocumentService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    deleteDocument (uuid) {
      DocumentService.delete(uuid).then(() => {
        router.push({ name: 'documents.index' })
      })
    },
    fetchFile () {
      DocumentService.show(this.$route.params.uuid).then((response) => {
        this.setData(response.data)
        this.model.file = response.data.file
        store.commit('loaded')
      })
    },
    setData (data) {
      this.model = data
    },
    onSubmit () {
      DocumentService.update(this.model.uuid, this.model).then(() => {
        router.push({ name: 'documents.index' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
