import ApiService from './api.service'

const SeasonService = {
  index (search = '', page = 1) {
    return ApiService.get(`api/v1/admin/seasons?search=${search}&page=${page}`)
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/seasons/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v1/admin/seasons/' + uuid, data)
  },

  store (data) {
    return ApiService.post('api/v1/admin/seasons', data)
  }
}

export default SeasonService
