export function loginToGoogleApi (callbackFunction, data) {
  window.google.accounts.oauth2.initTokenClient({
    client_id: '165481144195-tckudqhki1qu4c8l54a4ocgsalqjsps4.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/youtube',
    ux_mode: 'popup',
    callback: (response) => {
      localStorage.setItem('yt-access-token', response.access_token)
      callbackFunction(data)
    }
  }).requestAccessToken()
}
