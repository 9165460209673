<template>
  <header id="page-header">
    <!-- Header Content -->
    <div class="content-header">
      <!-- Left Section -->
      <div class="d-flex align-items-center">
        <!-- Toggle Sidebar -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
        <button type="button"
                @click="toggleSidebar()"
                class="btn btn-sm btn-dual mr-2 d-lg-none">
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <!-- END Toggle Sidebar -->

        <!-- Toggle Mini Sidebar -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
        <button type="button"
                class="btn btn-sm btn-dual d-none mr-1 d-lg-inline-block"
                @click="toggleMiniSidebar()">
          <i class="fa fa-fw fa-ellipsis-v"></i>
        </button>
        <button v-if="finishedGames.length" @click="$modal.show('finished-games')" type="button" class="btn btn-sm btn-danger text-white ml-1">
          <i class="fa fa-fw fa-bell"></i>
        </button>
        <button v-if="timeline?.gamesCount > 0" @click="publishTimeline()" type="button" class="btn btn-sm btn-success text-white ml-1">
          <i class="fa fa-fw fa-calendar-check-o"></i> <span class="badge badge-pill badge-light">{{timeline?.gamesCount}}</span>
        </button>
        <router-link v-if="imageRequestsCount > 0" :to="{name:'imageRequests.index'}" type="button" class="btn btn-sm btn-warning text-white ml-1">
          <i class="fa fa-fw fa-picture-o"></i> <span class="badge badge-pill badge-light">{{imageRequestsCount}}</span>
        </router-link>
        <!-- END Toggle Mini Sidebar -->

        <!-- END Search Form -->
      </div>
      <!-- END Left Section -->

      <!-- Right Section -->
      <div class="d-flex align-items-center">
        <div class="dropdown d-inline-block ml-2">
          <button type="button"
                  class="btn btn-sm btn-dual"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <i style="font-size: 20px;" class="fa fa-language"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
               aria-labelledby="page-header-notifications-dropdown">
            <div class="p-2 bg-primary text-center">
              <h5 class="dropdown-header text-uppercase text-white">{{ $tc('language.name', 2) }}</h5>
            </div>
            <ul class="nav-items mb-0">
              <li v-for="lng in availableLanguages" :class="({'bg-info-light': currentLanguage === lng.id})" :key="lng.id">
                <a class="text-dark media py-2" href="#" @click.prevent="setLanguage(lng.id)">
                  <div class="mr-2 ml-3">
                    <i style="font-size: 16px" :class="'flag-icon flag-icon-' + lng.img"></i>
                  </div>
                  <div class="media-body pr-2">
                    <div class="font-w600">{{ $t(lng.name) }}</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- END Language Dropdown -->
        <!-- User Dropdown -->
        <div class="dropdown d-inline-block ml-2">
          <button type="button"
                  class="btn btn-sm btn-dual"
                  id="page-header-user-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <img class="rounded"
                 src="@/assets/media/avatars/avatar10.jpg"
                 alt="Header Avatar"
                 style="width: 18px;">
            <span class="d-none d-sm-inline-block ml-1">{{ $store.state.user.name }}</span>
            <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
               aria-labelledby="page-header-user-dropdown">
            <div class="p-3 text-center bg-primary">
              <img class="img-avatar img-avatar48 img-avatar-thumb"
                   src="@/assets/media/avatars/avatar10.jpg"
                   alt="">
            </div>
            <div class="p-2">
              <h5 class="dropdown-header text-uppercase">User Options</h5>
<!--              <a class="dropdown-item d-flex align-items-center justify-content-between"-->
<!--                 href="javascript:void(0)">-->
<!--                <span>Profile</span>-->
<!--                <span>-->
<!--                    <span class="badge badge-pill badge-success">1</span>-->
<!--                    <i class="si si-user ml-1"></i>-->
<!--                </span>-->
<!--              </a>-->
              <router-link :to="{name: 'members.index'}"
                           class="dropdown-item d-flex align-items-center justify-content-between">
                <span>{{ $tc('member.name', 2) }}</span>
                <i class="si si-users"></i>
              </router-link>
              <router-link :to="{name: 'activities.index'}"
                           class="dropdown-item d-flex align-items-center justify-content-between">
                <span>{{ $tc('activity.name', 2) }}</span>
                <i class="si si-clock"></i>
              </router-link>
              <router-link :to="{name: 'settings.index'}"
                           class="dropdown-item d-flex align-items-center justify-content-between">
                <span>{{ $t('settings.name', 1) }}</span>
                <i class="si si-settings"></i>
              </router-link>
              <div role="separator" class="dropdown-divider"></div>
              <h5 class="dropdown-header text-uppercase">Actions</h5>
              <a class="dropdown-item d-flex align-items-center justify-content-between"
                 @click.prevent="logout()">
                <span>Log Out</span>
                <i class="si si-logout ml-1"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- END User Dropdown -->

        <!-- Notifications Dropdown -->
        <!--        <div class="dropdown d-inline-block ml-2">-->
        <!--          <button type="button"-->
        <!--                  class="btn btn-sm btn-dual"-->
        <!--                  id="page-header-notifications-dropdown"-->
        <!--                  data-toggle="dropdown"-->
        <!--                  aria-haspopup="true"-->
        <!--                  aria-expanded="false">-->
        <!--            <i class="si si-bell"></i>-->
        <!--            <span class="badge badge-primary badge-pill">6</span>-->
        <!--          </button>-->
        <!--          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 border-0 font-size-sm"-->
        <!--               aria-labelledby="page-header-notifications-dropdown">-->
        <!--            <div class="p-2 bg-primary text-center">-->
        <!--              <h5 class="dropdown-header text-uppercase text-white">Notifications</h5>-->
        <!--            </div>-->
        <!--            <ul class="nav-items mb-0">-->
        <!--              <li>-->
        <!--                <a class="text-dark media py-2" href="javascript:void(0)">-->
        <!--                  <div class="mr-2 ml-3">-->
        <!--                    <i class="fa fa-fw fa-check-circle text-success"></i>-->
        <!--                  </div>-->
        <!--                  <div class="media-body pr-2">-->
        <!--                    <div class="font-w600">You have a new follower</div>-->
        <!--                    <small class="text-muted">15 min ago</small>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a class="text-dark media py-2" href="javascript:void(0)">-->
        <!--                  <div class="mr-2 ml-3">-->
        <!--                    <i class="fa fa-fw fa-plus-circle text-info"></i>-->
        <!--                  </div>-->
        <!--                  <div class="media-body pr-2">-->
        <!--                    <div class="font-w600">1 new sale, keep it up</div>-->
        <!--                    <small class="text-muted">22 min ago</small>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a class="text-dark media py-2" href="javascript:void(0)">-->
        <!--                  <div class="mr-2 ml-3">-->
        <!--                    <i class="fa fa-fw fa-times-circle text-danger"></i>-->
        <!--                  </div>-->
        <!--                  <div class="media-body pr-2">-->
        <!--                    <div class="font-w600">Update failed, restart server</div>-->
        <!--                    <small class="text-muted">26 min ago</small>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a class="text-dark media py-2" href="javascript:void(0)">-->
        <!--                  <div class="mr-2 ml-3">-->
        <!--                    <i class="fa fa-fw fa-plus-circle text-info"></i>-->
        <!--                  </div>-->
        <!--                  <div class="media-body pr-2">-->
        <!--                    <div class="font-w600">2 new sales, keep it up</div>-->
        <!--                    <small class="text-muted">33 min ago</small>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a class="text-dark media py-2" href="javascript:void(0)">-->
        <!--                  <div class="mr-2 ml-3">-->
        <!--                    <i class="fa fa-fw fa-user-plus text-success"></i>-->
        <!--                  </div>-->
        <!--                  <div class="media-body pr-2">-->
        <!--                    <div class="font-w600">You have a new subscriber</div>-->
        <!--                    <small class="text-muted">41 min ago</small>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a class="text-dark media py-2" href="javascript:void(0)">-->
        <!--                  <div class="mr-2 ml-3">-->
        <!--                    <i class="fa fa-fw fa-check-circle text-success"></i>-->
        <!--                  </div>-->
        <!--                  <div class="media-body pr-2">-->
        <!--                    <div class="font-w600">You have a new follower</div>-->
        <!--                    <small class="text-muted">42 min ago</small>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--            <div class="p-2 border-top">-->
        <!--              <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">-->
        <!--                <i class="fa fa-fw fa-arrow-down mr-1"></i> Load More..-->
        <!--              </a>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- END Notifications Dropdown -->

<!--         Toggle Side Overlay -->
<!--         Layout API, functionality initialized in Template._uiApiLayout() -->
                <button type="button"
                        class="btn btn-sm btn-dual ml-2" @click="sidebar = 'open'">
                  <i class="si si-clock"></i>
                </button>
<!--         END Toggle Side Overlay -->
      </div>
      <!-- END Right Section -->
    </div>
    <!-- END Header Content -->

    <!-- Header Search -->
    <!--    <div id="page-header-search" class="overlay-header bg-white">-->
    <!--      <div class="content-header">-->
    <!--        <form class="w-100" action="/dashboard" method="POST">-->
    <!--          <div class="input-group input-group-sm">-->
    <!--            <div class="input-group-prepend">-->
    <!--              &lt;!&ndash; Layout API, functionality initialized in Template._uiApiLayout() &ndash;&gt;-->
    <!--              <button type="button"-->
    <!--                      class="btn btn-danger"-->
    <!--                      data-toggle="layout"-->
    <!--                      data-action="header_search_off">-->
    <!--                <i class="fa fa-fw fa-times-circle"></i>-->
    <!--              </button>-->
    <!--            </div>-->
    <!--            <input type="text"-->
    <!--                   class="form-control show"-->
    <!--                   placeholder="Search or hit ESC.."-->
    <!--                   id="page-header-search-input"-->
    <!--                   name="page-header-search-input">-->
    <!--          </div>-->
    <!--        </form>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- END Header Search -->

    <!-- Header Loader -->
    <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
    <div id="page-header-loader" class="overlay-header bg-white">
      <div class="content-header">
        <div class="w-100 text-center">
          <i class="fa fa-fw fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
    <!-- END Header Loader -->
  </header>
</template>

<script>
import UserService from '../../services/user.service'
import SearchService from '../../services/search.service'
import _ from 'lodash'
import { useRouteQuery } from '@/use/useRouteQuery'
import { useTimeline } from '@/use/useTimeline'
import { useImageRequest } from '@/use/useImageRequest'

export default {
  setup () {
    const { timeline, fetchCurrentTimeline, publishTimeline } = useTimeline()
    const { imageRequestsCount, fetchImageRequestsCount } = useImageRequest()
    const sidebar = useRouteQuery('activities')

    return {
      sidebar,
      timeline,
      fetchCurrentTimeline,
      fetchImageRequestsCount,
      imageRequestsCount,
      publishTimeline
    }
  },
  data () {
    return {
      search: '',
      searchItems: [],
      user: {}
    }
  },
  watch: {
    language: 'changeLanguage',
    search: 'globalSearch'

  },
  mounted () {
    // this.getUser()

  },
  methods: {
    toggleMiniSidebar () {
      this.$store.dispatch('toggleMiniNav')
    },
    toggleSidebar () {
      this.$store.dispatch('toggleNav')
    },
    globalSearch: _.debounce(function () {
      if (this.search === '' || this.search.length < 3) {
        this.searchItems = []
      } else {
        SearchService.global(this.search).then((res) => {
          this.searchItems = res.data
        })
      }
    }, 200),
    itemClass (item) {
      switch (item.type) {
        case 'Coach':
          return 'fa-user-tie'
        case 'Player':
          return 'fa-child'
        case 'Club':
          return 'fa-city'
        case 'League':
          return 'fa-clipboard-list'
        case 'Season':
          return 'fa-trophy'
        case 'Venue':
          return 'fa-map-marker-alt'
      }
      return 'fa-star text-success'
    },
    toEditPage (item) {
      this.search = ''
      this.searchItems = []
      switch (item.type) {
        case 'Coach':
          this.$router.push({ name: 'coaches.edit', params: { uuid: item.uuid } })
          break
        case 'Player':
          this.$router.push({ name: 'players.edit', params: { uuid: item.uuid } })
          break
        case 'Club':
          this.$router.push({ name: 'clubs.edit', params: { uuid: item.uuid } })
          break
        case 'League':
          this.$router.push({ name: 'leagues.edit', params: { uuid: item.uuid } })
          break
        case 'Season':
          this.$router.push({ name: 'seasons.edit', params: { uuid: item.uuid } })
          break
        case 'Venue':
          this.$router.push({ name: 'venues.edit', params: { uuid: item.uuid } })
          break
      }
    },
    setLanguage (language) {
      this.$store.dispatch('setLanguage', language)
    },
    logout () {
      UserService.logout()
    }
  },
  computed: {
    availableLanguages () {
      return this.$store.state.availableLanguages
    },
    currentLanguage () {
      return this.$store.state.language
    },
    finishedGames () {
      return this.$store.state.finishedGames
    }
  }
}
</script>
