import ApiService from './api.service'

const DebtService = {
  index (search = '', page = 1, type = 'player') {
    return ApiService.get(`api/v1/admin/debts?search=${search}&page=${page}&type=${type}`)
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/debts/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v1/admin/debts/' + uuid, data)
  },

  payDebt (uuid, data) {
    return ApiService.putWithoutLoading(`api/v1/admin/debts/${uuid}/pay-debt`)
  },

  store (data) {
    return ApiService.post('api/v1/admin/debts', data)
  }
}

export default DebtService
