const fields = [
  {
    key: 'firstName',
    type: 'input',
    templateOptions: {
      label: 'firstName'
    }
  },
  {
    key: 'lastName',
    type: 'input',
    templateOptions: {
      label: 'lastName'
    }
  },
  {
    key: 'middleName',
    type: 'input',
    templateOptions: {
      label: 'middleName'
    }
  },
  // {
  //   key: 'email',
  //   type: 'input',
  //   templateOptions: {
  //     inputType: 'email',
  //     label: 'email'
  //   }
  // },
  {
    key: 'position',
    type: 'ntm-select',
    optionsApi: 'player_positions',
    isString: true,
    templateOptions: {
      label: 'position',
      translated: true
    }
  },
  {
    key: 'birthYear',
    type: 'input',
    templateOptions: {
      inputType: 'number',
      label: 'birthYear'
    }
  },
  {
    key: 'showPlayerPictureOnWebsite',
    type: 'ntm-select',
    options: ['DEFAULT', 'SHOW', 'HIDE'],
    isString: true,
    templateOptions: {
      translated: true,
      label: 'showPlayerPictureOnWebsite'
    }
  }
]

export default fields
