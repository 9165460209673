export const debtTypes = [
  'PUNISHMENT',
  'PARTICIPATION',
  'OTHER'
]

export const addDebtFields = [
  {
    key: 'club',
    type: 'ntm-v-select',
    optionsApi: 'clubs',
    templateOptions: {
      label: 'club'
    }
  },
  {
    key: 'player',
    type: 'ntm-v-select',
    optionsApi: 'players',
    templateOptions: {
      label: 'player'
    }
  },
  {
    key: 'amount',
    type: 'input',
    templateOptions: {
      attr: {
        type: 'number'
      },
      label: 'amount'
    }
  },
  {
    key: 'type',
    type: 'ntm-select',
    isString: true,
    multiple: false,
    options: debtTypes,
    templateOptions: {
      translated: true,
      label: 'type'
    }
  },
  {
    key: 'desc',
    type: 'textarea',
    templateOptions: {
      label: 'description'
    }
  },
  {
    key: 'isPaid',
    type: 'boolean',
    templateOptions: {
      label: 'paid'
    }
  }
]
export const updateDebtFields = [
  {
    key: 'amount',
    type: 'input',
    templateOptions: {
      attr: {
        type: 'number'
      },
      label: 'amount'
    }
  },
  {
    key: 'type',
    type: 'ntm-select',
    isString: true,
    multiple: false,
    options: debtTypes,
    templateOptions: {
      translated: true,
      label: 'type'
    }
  },
  {
    key: 'desc',
    type: 'textarea',
    templateOptions: {
      label: 'description'
    }
  },
  {
    key: 'isPaid',
    type: 'boolean',
    templateOptions: {
      label: 'paid'
    }
  }
]
