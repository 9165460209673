<template>
  <button @click="downloadZip()" class="btn btn-sm btn-info" style="width: 50px">
    <i v-if="!loading" class="fa fa-download mr-1"></i>
    <i v-if="!loading" class="fa fa-picture-o"></i>
    <i v-if="loading" class="fa fa-spinner fa-spin"></i>
  </button>
</template>

<script>
import ExportService from '../../services/export.service'

export default {
  name: 'TeamPlayersImagesDownload',
  props: {
    schedule: undefined,
    team: undefined
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    downloadZip () {
      this.loading = true
      ExportService.teamPlayersImagesDownload(this.schedule.uuid, this.team.uuid).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${this.team.name}.zip`)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
