const fields = [
  {
    key: 'winner',
    type: 'ntm-select',
    isString: true,
    options: [
      'HOME',
      'DRAW',
      'AWAY'
    ],
    templateOptions: {
      translated: true,
      label: 'winner'
    }
  },
  {
    key: 'isTerminated',
    type: 'boolean',
    templateOptions: {
      label: 'isTerminated'
    }
  },
  {
    key: 'report',
    type: 'html',
    templateOptions: {
      label: 'report'
    }
  }
]

export default fields
