import ApiService from './api.service'

const MediaService = {
  upload (assetUuid, type, formData) {
    return ApiService.post('api/v1/admin/media/' + assetUuid + '/images/' + type, formData)
  },
  uploadAttachment (formData) {
    return ApiService.post('api/v1/admin/media/attachment', formData)
  },
  delete (uuid) {
    return ApiService.deleteWithoutLoading('api/v1/admin/media/' + uuid)
  },
  getByType (assetType, assetUuid, type) {
    return ApiService.get('api/v1/admin/media/' + assetType + '/' + assetUuid + '/images/' + type)
  },
  deleteByType (assetType, assetUuid, type) {
    return ApiService.deleteWithoutLoading('api/v1/admin/media/' + assetType + '/' + assetUuid + '/images/' + type)
  },
  getFirstImageByType (assetType, assetUuid, type, conversion) {
    return ApiService.get(`api/v1/admin/media/${assetType}/${assetUuid}/images/${type}/conversion/${conversion}/first`)
  }
}

export default MediaService
