import ApiService from './api.service'

const LeagueService = {
  index (search = '', page = 1) {
    return ApiService.get(`api/v1/admin/leagues?search=${search}&page=${page}`)
  },

  changeOrder (data) {
    return ApiService.putWithoutLoading('api/v1/admin/leagues/change-order', data)
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/leagues/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v1/admin/leagues/' + uuid, data)
  },

  store (data) {
    return ApiService.post('api/v1/admin/leagues', data)
  }
}

export default LeagueService
