<template>
  <div class="penalty_index">
    <page-header :title="$tc('penalty.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template #options>
          <multiselect :multiple="false"
                       :placeholder="$tc('select.schedule', 1)"
                       :custom-label="customLabel"
                       v-model="selectedSchedule"
                       :options="schedules"></multiselect>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="tableFields" :data="model" @pageChange="setPage" url="api/penalties" index="penalties" :actions="true">
          </ntm-table>
        </div>
      </ntm-block>
      <!-- END Full Table -->
    </page-content>
  </div>
</template>
<script>
import PenaltyService from '@/services/penalty.service'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import ScheduleService from '@/services/schedule.service'
import Multiselect from 'vue-multiselect'

export default {
  mixins: [basicIndexMixin],
  components: {
    Multiselect
  },
  data () {
    return {
      schedules: [],
      selectedSchedule: null
    }
  },
  beforeRouteEnter (to, from, next) {
    Promise.all([PenaltyService.index(1), ScheduleService.index()]).then((response) => {
      next(vm => vm.setCustomData(response))
    })
  },
  computed: {
    config () {
      return this.$store.state.config
    },
    tableFields () {
      const fields = [
        {
          name: 'name',
          prop: 'player.name'
        },
        {
          name: 'yearOfBirth',
          prop: 'player.birthYear'
        }
      ]

      const ps = this.config?.player_stats

      if (ps) {
        Object.keys(ps).forEach((stat) => {
          if (ps[stat].penalty) {
            fields.push({
              name: stat,
              prop: stat
            })
          }
        })
      }

      return fields
    }
  },
  watch: {
    selectedSchedule: 'selectSchedule'
  },
  methods: {
    customLabel (o) {
      return `${o.name ? o.name : ''}`
    },
    setCustomData (response) {
      this.model = response[0].data
      this.schedules = response[1].data
    },
    fetchData () {
      PenaltyService.index(this.page, this.selectedSchedule?.uuid).then((response) => {
        this.setData(response.data)
      })
    },
    selectSchedule () {
      this.page = 1
      this.$nextTick(() => {
        this.fetchData()
      })
    }
  }
}
</script>
