<template>
   <div class="form-group" :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
      <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
     <div class="editor-wrapper">
       <vue-editor useCustomImageHandler @image-added="handleImageAdded" :editor-toolbar="customToolbar" :editorOptions="editorSettings" v-model="model[field.key]" :placeholder="to.placeholder" ></vue-editor>
       <span class="help-block form-text text-danger"
             v-if="form.$errors[field.key].length > 0"
             v-text="$t('validation.' + field.key + '.' + form.$errors[field.key][0])" />
       <content-loader v-if="this.loading"></content-loader>
     </div>
      <!-- <error-display v-if="form.$errors[field.key].lenght > 0" :form="form" :field="field.key"></error-display> -->
   </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import MediaService from '@/services/media.service'
import ContentLoader from '@/components/layout/ContentLoader'
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import htmlEditButton from 'quill-html-edit-button'

Quill.register({
  'modules/imageDrop': ImageDrop,
  'modules/imageResize': ImageResize,
  'modules/htmlEditButton': htmlEditButton
})
export default {
  mixins: [baseField],
  data () {
    return {
      loading: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean'] // remove formatting button
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
          htmlEditButton: {
            prependSelector: '#main-container',
            buttonHTML: '<strong><i class="fa fa-code" aria-hidden="true"></i></strong>'
          }
        }
      }
    }
  },
  components: {
    ContentLoader,
    VueEditor
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      this.loading = true
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('uuid', this.to.uuid)
      formData.append('assetType', this.to.assetType)
      formData.append('type', 'attachment')

      MediaService.uploadAttachment(formData)
        .then(res => {
          const url = res.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
.editor-wrapper {
  position: relative;
}
</style>
