const fields = [
  {
    key: 'title',
    type: 'input',
    templateOptions: {
      label: 'title'
    }
  },
  {
    key: 'email',
    type: 'input',
    templateOptions: {
      inputType: 'email',
      label: 'email'
    }
  },
  {
    key: 'phone',
    type: 'input',
    templateOptions: {
      label: 'phone'
    }
  },
  {
    key: 'address',
    type: 'input',
    templateOptions: {
      label: 'address'
    }
  },
  {
    key: 'postalCode',
    type: 'input',
    templateOptions: {
      label: 'postalCode'
    }
  },
  {
    key: 'mapIframeLink',
    type: 'input',
    templateOptions: {
      label: 'mapIframeLink'
    }
  },
  {
    key: 'aboutUs',
    type: 'html',
    templateOptions: {
      label: 'aboutUs',
      uuid: null,
      assetType: null
    }
  },
  {
    key: 'shortDescription',
    type: 'input',
    templateOptions: {
      label: 'shortDescription'
    }
  },
  {
    key: 'metaKeywords',
    type: 'input',
    templateOptions: {
      label: 'metaKeywords'
    }
  },
  {
    key: 'metaTitle',
    type: 'input',
    templateOptions: {
      label: 'metaTitle'
    }
  },
  {
    key: 'metaDescription',
    type: 'input',
    templateOptions: {
      label: 'metaDescription'
    }
  },
  {
    key: 'customTeamPlayerRegistrationLink',
    type: 'input',
    templateOptions: {
      label: 'customTeamPlayerRegistrationLink'
    }
  },
  {
    key: 'facebookUrl',
    type: 'input',
    templateOptions: {
      label: 'fb'
    }
  },
  {
    key: 'twitterUrl',
    type: 'input',
    templateOptions: {
      label: 'tw'
    }
  },
  {
    key: 'instagramUrl',
    type: 'input',
    templateOptions: {
      label: 'insta'
    }
  },
  {
    key: 'pinterestUrl',
    type: 'input',
    templateOptions: {
      label: 'pin'
    }
  },
  {
    key: 'youtubeUrl',
    type: 'input',
    templateOptions: {
      label: 'yt'
    }
  },
  {
    key: 'lang',
    type: 'ntm-select',
    isString: true,
    options: [],
    templateOptions: {
      translationObject: 'language',
      label: 'lang',
      translated: true
    }
  },
  {
    key: 'timezone',
    type: 'ntm-select',

    isString: true,
    options: [],
    templateOptions: {
      label: 'timezone'
    }
  },
  {
    key: 'formatOfDate',
    type: 'ntm-select',

    isString: true,
    options: ['DMY', 'MDY', 'YMD'],
    templateOptions: {
      translated: true,
      label: 'formatOfDate'
    }
  },
  {
    key: 'formatOfTime',
    type: 'ntm-select',

    isString: true,
    options: ['12h-clock', '24h-clock'],
    templateOptions: {
      label: 'formatOfTime',
      translated: true
    }
  },
  {
    key: 'defaultShowPlayerPictureOnWebsite',
    type: 'boolean',
    templateOptions: {
      label: 'defaultShowPlayerPictureOnWebsite'
    }
  },
  {
    key: 'colorButton',
    type: 'linear-color',
    templateOptions: {
      label: 'colorButton'
    }
  },
  {
    key: 'color1',
    type: 'color',
    templateOptions: {
      label: 'color1'
    }
  },
  {
    key: 'color2',
    type: 'color',
    templateOptions: {
      label: 'color2'
    }
  },
  {
    key: 'color3',
    type: 'color',
    templateOptions: {
      label: 'color3'
    }
  },
  {
    key: 'color4',
    type: 'color',
    templateOptions: {
      label: 'color4'
    }
  },
  {
    key: 'color5',
    type: 'color',
    templateOptions: {
      label: 'color5'
    }
  }
]

export default fields
