<template>
  <div class="league_index">
    <page-header :title="$tc('league.name', 2)">
    </page-header>
    <page-content>

      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <ntm-block>
            <template slot="options">
              <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
            </template>
          </ntm-block>
          <draggable handle=".drag-handle"
                     @change="changeOrder"
                     v-bind="dragOptions"
                     v-model="model"
                     @start="drag = true"
                     @end="drag = false">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <div class="block block-rounded mb-3" v-for="item in model" :key="item.uuid">
                <div class="block-header block-header-default">
                  <h3 class="block-title"><i class="fa fa-bars drag-handle mr-3"></i> {{item.name}}</h3>
                  <div class="block-options">
                    <router-link :to="{ name: 'leagues.edit', params: {uuid: item.uuid }}" class="btn btn-sm btn-primary">
                      <i class="fa fa-fw fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </page-content>
  </div>
</template>
<script>
import LeagueService from '@/services/league.service'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import draggable from 'vuedraggable'

export default {
  components: { draggable },
  mixins: [basicIndexMixin],
  data () {
    return {
      model: [],
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    LeagueService.index('').then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    changeOrder (data) {
      LeagueService.changeOrder(this.model)
    },
    fetchData () {
      LeagueService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    },
    createPage () {
      router.push({ name: 'leagues.create' })
    },
    setData (data) {
      this.model = data
    }
  }
}
</script>
