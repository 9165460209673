import ApiService from './api.service'
import store from '@/store'

const ScheduleService = {
  index (search = '') {
    const params = { search, season: store.state.currentSeason.uuid }
    return ApiService.get('api/v1/admin/schedules', { params })
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/schedules/' + uuid)
  },

  videoVenues (uuid) {
    return ApiService.get('api/v1/admin/schedules/' + uuid + '/venue-videos')
  },

  delete (uuid) {
    return ApiService.delete('api/v1/admin/schedules/' + uuid)
  },
  update (uuid, data) {
    return ApiService.put('api/v1/admin/schedules/' + uuid, data)
  },
  upsertFantasy (uuid, data) {
    return ApiService.postWithoutLoading(`api/v1/admin/schedules/${uuid}/fantasy-league`, data)
  },
  deleteFantasy (uuid, data) {
    return ApiService.deleteWithoutLoading(`api/v1/admin/schedules/${uuid}/fantasy-league`, data)
  },
  nameImagesOfAllPlayers (uuid, data) {
    return ApiService.postWithoutLoading(`api/v1/admin/schedules/${uuid}/name-images-of-all-players`, data)
  },
  store (data) {
    return ApiService.postWithoutLoading('api/v1/admin/schedules', data)
  },
  changeOrder (data) {
    return ApiService.putWithoutLoading('api/v1/admin/schedules/change-order', data)
  },

  addGame (uuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + uuid + '/addGame', data)
  },
  addGroup (uuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + uuid + '/addGroup', data)
  },

  deleteGroup (uuid, groupUuid) {
    return ApiService.delete('api/v1/admin/schedules/' + uuid + '/deleteGroup/' + groupUuid)
  },

  changeGroup (uuid, teamUuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + uuid + '/teams/' + teamUuid + '/changeGroup', data)
  },

  generate (uuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + uuid + '/generate', data)
  },

  deleteAllGames (uuid, type = 'LEAGUE') {
    return ApiService.delete(`api/v1/admin/schedules/${uuid}/deleteAllGames?type=${type}`)
  },

  makePlayoff (uuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + uuid + '/playoff', data)
  },

  editRounds (uuid, data) {
    return ApiService.put('api/v1/admin/schedules/' + uuid + '/playoff/editRounds', data)
  },

  addGameToRoundDuels (uuid, roundUuid) {
    return ApiService.putWithoutLoading(`api/v1/admin/schedules/${uuid}/playoff/rounds/${roundUuid}/addGameToRoundDuels`)
  },
  deleteGameToRoundDuels (uuid, roundUuid) {
    return ApiService.deleteWithoutAsking(`api/v1/admin/schedules/${uuid}/playoff/rounds/${roundUuid}/deleteGameToRoundDuels`)
  },

  getPlayoff (uuid) {
    return ApiService.get('api/v1/admin/schedules/' + uuid + '/playoff')
  },

  getDuelGames (uuid, playoff, duel) {
    return ApiService.get('api/v1/admin/schedules/' + uuid + '/playoff/' + playoff + '/duels/' + duel)
  },

  setDuelTeam (uuid, playoff, duel, data) {
    return ApiService.put('api/v1/admin/schedules/' + uuid + '/playoff/' + playoff + '/duels/' + duel + '/setDuelTeam', data)
  },

  removeDuelTeam (uuid, playoff, duel, slot) {
    return ApiService.delete('api/v1/admin/schedules/' + uuid + '/playoff/' + playoff + '/duels/' + duel + `/removeTeam?slot=${slot}`)
  },

  startPlayoff (uuid) {
    return ApiService.post('api/v1/admin/schedules/' + uuid + '/playoff/start')
  },

  deletePlayoff (uuid) {
    return ApiService.delete('api/v1/admin/schedules/' + uuid + '/playoff')
  },

  setDuelWinner (uuid, playoff, duel, data) {
    return ApiService.put('api/v1/admin/schedules/' + uuid + '/playoff/' + playoff + '/duels/' + duel + '/win', data)
  }
}

export default ScheduleService
