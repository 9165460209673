<template>
  <div class="about">
    <page-header :title="$t('news.edit')" :desc="model.title">
      <li class="breadcrumb-item"><router-link :to="{name:'news.index'}">{{$tc('news.name', 2)}}</router-link></li>
      <li class="breadcrumb-item">{{model.title}}</li>
    </page-header>
    <page-content>
      <form @submit.prevent="onSubmit()">
        <ntm-block :title="$tc('image.name', 1)">
          <ntm-crop :aspect-ratio="16/10" :max-width="1280" :max-height="800" conversion="lg" entity-type="news" :entity-uuid="$route.params.uuid" collection="preview"></ntm-crop>
        </ntm-block>
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn push-15" :class="{ 'btn-success': model.publishDate, 'btn-warning': !model.publishDate }">{{model.publishDate ? $t('saveAndPublish') : $t('saveAsDraft')}}</button>
        </ntm-block>
      </form>
    </page-content>
  </div>
</template>

<script>
import fields from './formDefinition'
import router from '@/router'
import NewsService from '../../services/news.service'

export default {
  // required properties, field, form, model
  data () {
    return {
      form: {},
      model: {},
      fields: fields,
      html: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    NewsService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  // beforeRouteUpdate (to, from, next) {
  //   NewsService.show(to.params.uuid).then((response) => {
  //     this.setData(response.data)
  //     store.commit('loaded')
  //   })
  // },
  mounted () {
    this.fields[3].templateOptions.uuid = this.model.uuid
    this.fields[3].templateOptions.assetType = 'news'
  },
  methods: {
    setData (data) {
      this.model = data
    },
    onSubmit () {
      NewsService.update(this.model.uuid, this.model).then((response) => {
        router.push({ name: 'news.index' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
