<template>
  <div class="form-group"
       :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
    <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
    <v-select :value="model[field.key]"
              @search="getOptions" :filter="(opt) => opt"
              :options="options"
              label="name"
              :get-option-label="getLabel"
              @option:selected="changed"
              :placeholder="$tc('select.option', field.multiple ? 2 : 1)"
              :multiple="field.multiple ? field.multiple : false"></v-select>
    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import ErrorDisplay from '../formly-bootstrap/components/errorDisplay'
import VueI18n from '@/i18n'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  mixins: [baseField],
  data () {
    return {
      options: []
    }
  },
  components: {
    vSelect,
    ErrorDisplay
  },
  mounted () {
    if (this.field.options) {
      this.options = this.field.options
    } else {
      this.getOptions()
    }
  },
  watch: {
    'field.options': 'updateOptions'
  },
  methods: {
    getLabel: function (val) {
      if (!this.field.customLabel) return val.name

      return this.field.customLabel(val)
    },
    customLabel (o) {
      if (this.isObject) {
        if (this.to.translated) {
          return `${o.name ? VueI18n.t('select.options.' + o) : ''}`
        }

        return `${o.name ? o.name : ''}`
      } else {
        if (this.to.translated) {
          return VueI18n.t('select.options.' + o)
        }

        return o
      }
    },
    updateOptions () {
      this.options = this.field.options
    },
    changed (value) {
      this.model[this.field.key] = value
    },
    getOptions: _.debounce(function (q) {
      const search = q || ''
      if (this.field.optionsApi && !this.field.options) {
        const params = { search }
        ApiService.get(`api/v1/admin/search/${this.field.optionsApi}`, { params })
          .then((response) => {
            this.options = response.data
          })
      }
    }, 300)
  },
  computed: {
    isObject () {
      if (typeof this.options[0] === 'string') {
        return false
      }
      return true
    }
  }
}
</script>
