<template>
  <div class="debt_index">
    <page-header :title="$tc('debt.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
            <div class="btn-group mr-3">
              <button class="btn btn-sm " :class="{'btn-info': type === 'player'}" @click="setType('player')">{{ $tc('player.name', 1) }}</button>
              <button class="btn btn-sm" :class="{'btn-info': type === 'club'}" @click="setType('club')">{{ $tc('club.name', 1) }}</button>
            </div>
<!--          <input type="text" class="form-control" :placeholder="$tc('search')"  v-model="search">-->
          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" url="api/debts" index="debts" :actions="true">
            <template #actions="{row}">
              <button @click="openDebts(row.uuid)" class="btn btn-sm btn-primary">
                <i class="fa fa-fw fa-eye"></i>
              </button>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
      <!-- END Full Table -->
    </page-content>
    <modal name="debts-modal" height="auto">
      <div class="content">
        <h3 class="text-center">{{selectedPlayerDebts?.name}}</h3>
        <div class="body">
          <debt class="mb-3" v-for="debt in selectedPlayerDebts?.debts" :debt="debt" @pay="playerPaid" :key="debt.uuid"></debt>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import DebtService from '@/services/debt.service'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import PlayerService from '@/services/player.service'
import ClubService from '../../services/club.service'
export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      selectedPlayerDebts: null,
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'amount',
          prop: 'amount'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    DebtService.index('', to.query.page, to.query.type).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  mounted () {
    if (!this.type) {
      this.type = 'player'
    }
  },
  methods: {
    setType (type) {
      this.type = type
      this.page = 1
      setTimeout(() => {
        this.fetchData()
      })
    },
    openDebts (uuid) {
      const service = this.type === 'player' ? PlayerService : ClubService

      service.debts(uuid).then((res) => {
        this.selectedPlayerDebts = res.data
        this.$modal.show('debts-modal')
      })
    },
    playerPaid (debt) {
      this.$swal.fire({
        title: `${this.selectedPlayerDebts?.name} (${debt.amount})`,
        text: this.$t('swal.payDebt.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('swal.payDebt.yes'),
        cancelButtonText: this.$t('swal.payDebt.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          DebtService.payDebt(debt.uuid).then(() => {
            const service = this.type === 'player' ? PlayerService : ClubService

            service.debts(this.selectedPlayerDebts.uuid).then((res) => {
              this.selectedPlayerDebts = res.data
              if (!this.selectedPlayerDebts.debts.length) {
                this.$modal.hide('debts-modal')
                this.fetchData()
              }
            })
          })
        }
      })
    },
    fetchData () {
      DebtService.index(this.search, this.page, this.type).then((response) => {
        this.setData(response.data)
      })
    },
    createPage () {
      router.push({ name: 'debts.create' })
    }
  }
}
</script>
