<template>
  <div class="mb-4 rounded">
    <div class="px-1 px-2 rounded-top" :class="bgColor">
      <div class="p-1 block-content block-content-full d-flex align-items-center justify-content-between">
        <p class="fw-semibold text-white mb-0">{{ activity.created_at }}</p>
        <span
          class="badge badge-light">{{ activity.subject ? $tc(`${activity.subjectType?.toLowerCase()}.name`, 1) : '' }}</span>
      </div>
      <div class="p-1 block-content block-content-full d-flex align-items-center justify-content-between">
        <p class="fw-semibold text-white mb-0 mr-4">{{ $t(event) }}</p>
        <p class="fw-semibold text-white mb-0">{{ activity.subject?.name }}</p>
      </div>
    </div>
    <div class="table-responsive">
      <table v-if="changedFields.length"
             class="table table-hover table-borderless table-vcenter fs-sm mb-0">
        <tbody>
        <template  v-for="additional in additionalFields">
          <tr :key="additional">
            <td class="px-2 py-1">
              <span class="fs-sm">{{ translatedField(additional) }}</span>
            </td>
            <td class="px-2 py-1" colspan="3" v-html="parseValue(additional, activity.properties.additional[additional], activity.event)">
            </td>
          </tr>
        </template>
        <template v-for="attr in changedFields">

          <tr v-if="IsNotEmpty(attr)" :key="attr" :class="{'text-muted-light': (activity.properties.old ? parseValue(attr, activity.properties.old[attr], activity.event) : '') === (activity.properties?.attributes ? parseValue(attr, activity.properties.attributes[attr], activity.event) : '')}">
            <td class="px-2 py-1">
              <span class="fs-sm">{{ translatedField(attr) }}</span>
            </td>
            <td v-if="activity.properties?.old" class="px-2 py-1" v-html="activity.properties.old ? parseValue(attr, activity.properties.old[attr], activity.event) : ''">
            </td>
            <td v-if="activity.properties?.attributes && activity.properties?.old" class="px-2 py-1"><i class="fa fa-arrow-right font-size-sm"></i></td>
            <td v-if="activity.properties?.attributes" class="px-2 py-1" v-html="activity.properties?.attributes ? parseValue(attr, activity.properties.attributes[attr], activity.event) : ''">
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div class="px-1 px-2 rounded-bottom" :class="bgColor">
      <div class="p-1">
        <p class="fw-semibold text-white text-right mb-0">{{ activity.user?.name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ActivityLog',
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      eventPatterns: {
        created: 'bg-success',
        generated: 'bg-success',
        restored: 'bg-success',
        updated: 'bg-primary',
        deleted: 'bg-danger',
        uploaded: 'bg-warning'
      },
      mappedFields: {
        'club.name': 'club',
        'venue.name': 'venue',
        'league.name': 'league'
      },
      ignoreParsedFields: [
        'code',
        'club.name',
        'venue.name'
      ],
      enumList: [
        'status',
        'type',
        'position',
        'showPlayerPictureOnWebsite',
        'formatOfDate',
        'formatOfTime'
      ]
    }
  },
  methods: {
    parseValue (attr, value, event) {
      if (event.includes('media')) {
        return value
      }
      if (value === null) {
        return `<span class="badge badge-info">${this.$t('empty')}</span>`
      }
      if (value === true) {
        return '<i class="fa fa-check text-success"></i>'
      }

      if (value === false) {
        return '<i class="fa fa-times text-danger"></i>'
      }

      if (this.ignoreParsedFields.includes(attr)) {
        return value
      }

      if (!value) {
        return ''
      }

      if (typeof value === 'string') {
        const regex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|\\-)(?:\d{2}):?(?:\d{2}))/gm

        if (regex.test(value)) {
          const date = new Date(value)

          return `${this.$d(date, 'numericDateTime')}`
        }

        if (this.isEnumOption(attr)) {
          return this.$t(`select.options.${value}`)
        }
      }

      return value
    },
    isEnumOption (attr) {
      return this.enumList.includes(attr)
    },
    translatedField (field) {
      if (this.mappedFields[field]) {
        return this.$t(`formly.fields.${this.mappedFields[field]}`)
      }

      return this.$t(`formly.fields.${field}`)
    },
    IsNotEmpty (field) {
      const oldValue = this.activity.properties.old ? this.activity.properties.old[field] : null
      const newValue = this.activity.properties.attributes ? this.activity.properties.attributes[field] : null
      return oldValue || newValue
    }
  },
  computed: {
    additionalFields () {
      return Object.keys(this.activity.properties?.additional ?? {})
    },
    changedFields () {
      return [...new Set([...Object.keys(this.activity.properties?.attributes ?? {}), ...Object.keys(this.activity.properties?.old ?? {})])]
    },
    event () {
      return this.activity.event?.toLowerCase()
    },
    eventType () {
      const eventPatternKeys = Object.keys(this.eventPatterns)

      for (let i = 0; i < eventPatternKeys.length; i++) {
        if (this.event.includes(eventPatternKeys[i])) {
          return eventPatternKeys[i]
        }
      }

      return null
    },
    bgColor () {
      const eventPatternKeys = Object.keys(this.eventPatterns)

      for (let i = 0; i < eventPatternKeys.length; i++) {
        if (this.activity.event.includes(eventPatternKeys[i])) {
          return this.eventPatterns[eventPatternKeys[i]]
        }
      }

      return 'bg-warning'
    }
  }
}
</script>
<style scoped>
.text-muted-light {
  color: #b9b9b9;
}
</style>
