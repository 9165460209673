import Index from '@/views/schedules/Index'
import Show from '@/views/schedules/Show'
import CreateTeam from '../../views/schedules/teams/Create'
import EditTeam from '../../views/schedules/teams/Edit'
import EditGame from '../../views/schedules/games/Edit'
import PlayoffShow from '../../views/schedules/playoff/Show'
import CreatePlayoff from '../../views/schedules/createPlayoff/Show'
import VenueVideos from '@/views/schedules/createPlayoff/VenueVideos.vue'

export default [
  {
    path: ':clientId/schedules',
    name: 'schedules.index',
    component: Index
  },
  {
    path: ':clientId/schedules/:uuid',
    name: 'schedules.show',
    component: Show,
    meta: {
      title: 'Schedule'
    }
  },
  {
    path: ':clientId/schedules/:uuid/venue-videos',
    name: 'schedules.venueVideos',
    component: VenueVideos,
    meta: {
      title: 'Venue videos'
    }
  },
  {
    path: ':clientId/schedules/:uuid/playoffs',
    name: 'schedules.playoff.show',
    component: PlayoffShow,
    meta: {
      title: 'Schedule'
    }
  },
  {
    path: ':clientId/schedules/:uuid/teams/create',
    name: 'schedules.teams.create',
    component: CreateTeam,
    meta: {
      title: 'Add team'
    }
  },
  {
    path: ':clientId/schedules/:uuid/teams/:team',
    name: 'schedules.teams.show',
    component: EditTeam,
    meta: {
      title: 'Edit team'
    }
  },
  {
    path: ':clientId/schedules/:uuid/games/:game',
    name: 'schedules.games.show',
    component: EditGame,
    meta: {
      title: 'Edit game'
    }
  },
  {
    path: ':clientId/schedules/:uuid/create-playoff',
    name: 'schedules.createPlayoff.show',
    component: CreatePlayoff,
    meta: {
      title: 'Create playoff'
    }
  }
]
