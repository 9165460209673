import TimelineService from '@/services/timeline.service'
import { ref } from 'vue'
import VueI18n from '@/i18n'
import Swal from 'sweetalert2'

const timeline = ref(null)
export function useTimeline (publishedCallback = () => {}) {
  function fetchCurrentTimeline () {
    TimelineService.getCurrent().then(response => {
      timeline.value = response.data
    })
  }

  async function publishTimeline () {
    await Swal.fire({
      title: VueI18n.t('swal.publishSchedule.title'),
      text: VueI18n.t('swal.publishSchedule.text', { gamesCount: timeline.value.gamesCount }),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: VueI18n.t('swal.publishSchedule.yes'),
      cancelButtonText: VueI18n.t('swal.publishSchedule.no'),
      showCloseButton: true,
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value) {
        TimelineService.publish(timeline.value.id).then(response => {
          publishedCallback()
          fetchCurrentTimeline()
        })
      }
    })
  }

  return {
    timeline,
    fetchCurrentTimeline,
    publishTimeline
  }
}
