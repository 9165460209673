import { ref } from 'vue'
import ImageRequestService from '@/services/imageRequests.service'

const imageRequestsCount = ref(null)
export function useImageRequest () {
  function fetchImageRequestsCount () {
    ImageRequestService.count().then(response => {
      imageRequestsCount.value = response.data?.count
    })
  }

  return {
    fetchImageRequestsCount,
    imageRequestsCount
  }
}
