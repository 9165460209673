import { computed, nextTick, getCurrentInstance } from 'vue'

// eslint-disable-next-line
export let pendingQuery = null

function boolCheck (value) {
  if (typeof value === 'string') {
    switch (value.toLowerCase().trim()) {
      case 'true':
        return true
      case 'false':
        return false
      default:
        return value
    }
  }
  return value
}

export function useRouteQuery (
  name,
  initialValue = null,
  mode = 'single',
  { deleteIf = searchValue => !searchValue } = {}
) {
  const currentInstance = getCurrentInstance()

  const format = (searchKey) => {
    if (mode === 'multi') {
      return Array.isArray(searchKey) ? [...searchKey] || [] : (searchKey && [searchKey]) || []
    } else {
      return searchKey ? boolCheck(searchKey) : ''
    }
  }

  const updateQuery = (value) => {
    const newQuery = pendingQuery || { ...currentInstance.proxy.$route.query }

    newQuery[name] = value

    if (deleteIf(value)) { delete newQuery[name] }

    if (!pendingQuery) {
      pendingQuery = newQuery
      nextTick(() => {
        currentInstance.proxy.$router.push({ query: pendingQuery }).catch(() => null)
        pendingQuery = null
      })
    }
  }

  if (initialValue && !currentInstance.proxy.$route.query[name]) { updateQuery(initialValue) }

  return computed({
    get: () => format(currentInstance.proxy.$route.query[name]),
    set: updateQuery
  })
}
