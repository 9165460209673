import ApiService from './api.service'

const ImageRequestService = {
  index (page) {
    return ApiService.get(`api/v1/admin/imageRequests?page=${page}`)
  },

  count () {
    return ApiService.get('api/v1/admin/imageRequests/count')
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/imageRequests/' + uuid)
  },

  accept (uuid) {
    return ApiService.post('api/v1/admin/imageRequests/' + uuid + '/accept', {})
  },

  decline (uuid) {
    return ApiService.post('api/v1/admin/imageRequests/' + uuid + '/decline', {})
  }
}

export default ImageRequestService
