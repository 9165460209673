<template>
  <div >
    <page-header title="Venue videos">
      <li class="breadcrumb-item"><router-link :to="{name:'schedules.index'}">{{$tc('schedule.name', 2)}}</router-link></li>
      <li class="breadcrumb-item"><router-link :to="{name:'schedules.show', params: { uuid: model.uuid }}">{{model.name}}</router-link></li>
      <li class="breadcrumb-item">Venue videos</li>
    </page-header>
    <page-content>
      <div class="row">
        <div class="col-md-8 offset-sm-2">
          <div v-for="venue in model.venues" :key="venue.uuid">
            <ntm-block :title="venue.name">
              <template v-slot:options>
                <button class="btn btn-info btn-sm" @click="openAddVenueVideoModal(venue)">+</button>
              </template>
            </ntm-block>
            <div class="px-4 pb-2">
              <div v-for="embedVideo in venue.embedVideos" :key="embedVideo.uuid" class="d-flex align-items-center mb-1">
                <span>{{ $d(new Date(embedVideo.date.replace(/-/g, "/")), 'short') }}</span>  <button class="btn btn-danger btn-sm ml-3" @click="deleteEmbedVideo(embedVideo)"><i class="fa fa-trash"></i></button>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>

    </page-content>

    <ntm-modal size="sm" title="Add venue video" ref="addVenueVideo">
      <template v-slot:content>
        <form @submit.prevent="submitAddVenueVideo()">
          <formly-form :form="addVenueVideo.form"
                       :model="addVenueVideo.data"
                       :fields="addVenueVideo.fields">
          </formly-form>

          <button class="btn btn-success push-15" >{{$t('save')}}</button>
        </form>
      </template>
    </ntm-modal>
  </div>
</template>

<script>

import ScheduleService from '../../../services/schedule.service'
import seasonShowMixin from '@/mixins/season.show.mixin'
import EmbedVideoService from '@/services/embedVideo.service'

export default {
  mixins: [seasonShowMixin],
  beforeRouteEnter (to, from, next) {
    ScheduleService.videoVenues(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  data () {
    return {
      addVenueVideo: {
        form: {},
        data: {},
        fields: [
          {
            key: 'date',
            type: 'datepicker',
            format: 'YYYY-MM-DD',
            templateOptions: {
              onlyDate: true,
              autoClose: true,
              label: 'date'
            }
          },
          {
            key: 'code',
            type: 'textarea',
            templateOptions: {
              attrs: {
                rows: 5
              },
              label: 'embedVideoCode'
            }
          }
        ]
      },
      model: {}
    }
  },
  methods: {
    fetchData () {
      ScheduleService.videoVenues(this.$route.params.uuid).then((response) => {
        this.setData(response.data)
      })
    },
    openAddVenueVideoModal (venue) {
      this.addVenueVideo.data = {
        schedule_uuid: this.model.uuid,
        venue_uuid: venue.uuid,
        date: null,
        code: ''
      }

      this.addVenueVideo.data.venue_uuid = venue.uuid
      this.$refs.addVenueVideo.open()
    },
    submitAddVenueVideo () {
      EmbedVideoService.store(this.addVenueVideo.data).then(() => {
        this.$refs.addVenueVideo.close()
        this.fetchData()
      })
    },
    deleteEmbedVideo (embedVideo) {
      EmbedVideoService.delete(embedVideo.uuid).then(() => {
        this.fetchData()
      })
    }
  }
}
</script>

<style lang="scss">

</style>
