<template>
  <div class="about">
    <page-header :title="$t('sponsor.edit')" :desc="model.name">
      <li class="breadcrumb-item"><router-link :to="{name:'sponsors.index'}">{{$tc('sponsor.name', 2)}}</router-link></li>
      <li class="breadcrumb-item">{{model.name}}</li>
    </page-header>
    <page-content>
      <form @submit.prevent="onSubmit()">
        <ntm-block title="Logo" :init-collapse="false">
          <ntm-crop entity-type="sponsor" :aspect-ratio="2/1" :max-width="500" :max-height="250" :entity-uuid="$route.params.uuid" conversion="lg" collection="logo" ></ntm-crop>
        </ntm-block>

        <ntm-block :title="$tc('crest')" :init-collapse="false">
          <ntm-crop entity-type="sponsor" :entity-uuid="$route.params.uuid" conversion="lg" collection="crest" :circle="true"></ntm-crop>
        </ntm-block>

        <ntm-block :title="$tc('livestream.logo')" :init-collapse="false">
          <ntm-crop entity-type="sponsor" :max-width="500" :max-height="500" :entity-uuid="$route.params.uuid" collection="broadcast"></ntm-crop>
        </ntm-block>

        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{$t('save')}}</button>
        </ntm-block>
      </form>
    </page-content>
  </div>
</template>

<script>
import fields from './formDefinition'
import SponsorService from '@/services/sponsor.service'
import router from '@/router'

export default {
  // required properties, field, form, model
  data () {
    return {
      form: {},
      model: {},
      fields: fields,
      uploading: false
    }
  },
  beforeRouteEnter (to, from, next) {
    SponsorService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  // beforeRouteUpdate (to, from, next) {
  //   SponsorService.show(to.params.uuid).then((response) => {
  //     this.setData(response.data)
  //     store.commit('loaded')
  //   })
  // },
  methods: {
    setData (data) {
      this.model = data
    },
    updateLogo () {
      SponsorService.show(this.$route.params.uuid).then((response) => {
        this.uploading = false
        this.model.logo = response.data.logo
      })
    },
    onSubmit () {
      SponsorService.update(this.model.uuid, this.model).then(() => {
        router.push({ name: 'sponsors.index' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
