import Index from '@/views/imageRequests/Index'
import Show from '@/views/imageRequests/Show'

export default [
  {
    path: ':clientId/imageRequests',
    name: 'imageRequests.index',
    component: Index
  },
  {
    path: ':clientId/imageRequests/:uuid',
    name: 'imageRequests.show',
    component: Show
  }
]
