import scheduleFormDefinition from '@/views/schedules/games/formDefinitions/scheduleFormDefinition'

const fields = [
  ...scheduleFormDefinition,
  {
    key: 'report',
    type: 'html',
    templateOptions: {
      label: 'report'
    }
  },
  {
    key: 'embedVideoCode',
    type: 'textarea',
    templateOptions: {
      attrs: {
        rows: 5
      },
      label: 'embedVideoCode'
    }
  }
]

export default fields
