<template>
  <div class="p-2 rounded" :class="{'bg-danger': debt.type === 'PUNISHMENT', 'bg-info': debt.type === 'PARTICIPATION', 'bg-warning': debt.type === 'OTHER'}" href="javascript:void(0)">
    <div class="d-flex align-items-center justify-content-between">
      <dl class="me-3 mb-0">
        <dt class="text-white h3 fw-extrabold mb-0">
          {{ debt.amount }}
        </dt>
        <dd class="text-white fs-sm fw-medium text-muted mb-0">
          {{ $t(`debt.types.${debt.type}`) }}<span v-if="debt.entityName"> - {{debt.entityName}}</span>
        </dd>
      </dl>
      <div>
        <button v-if="!debt.isPaid" class="btn btn-sm btn-success" @click="pay()">{{ $t('debt.markAsPaid') }}</button>
        <router-link :to="{name: 'debts.edit', params: {uuid: debt.uuid}}" class="btn btn-sm btn-outline-light ml-2" @click="pay()"><i class="fa fa-pencil"></i></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    debt: {
      type: Object
    }
  },
  methods: {
    pay () {
      this.$emit('pay', this.debt)
    }
  }
}
</script>
