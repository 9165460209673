import ApiService from './api.service'

const EmbedVideo = {
  store (data) {
    return ApiService.postWithoutLoading('api/v2/admin/embed_videos', data)
  },
  deleteWithoutAsking (uuid) {
    return ApiService.deleteWithoutAsking(`api/v2/admin/embed_videos/${uuid}`)
  },
  delete (uuid) {
    return ApiService.deleteWithoutLoading(`api/v2/admin/embed_videos/${uuid}`)
  }
}

export default EmbedVideo
