import { useStore } from '@/store'

const store = useStore()
export function useExternalLinks () {
  function getWebsiteDomain () {
    const domain = store.state.domains?.WEBSITE

    if (domain === 'localhost') {
      return 'http://localhost:8091'
    }

    return `https://${domain}`
  }

  function getRecordDomain () {
    const domain = store.state.domains?.SCOREKEEPER

    if (!domain) {
      return 'http://localhost:8082'
    }

    return `https://${domain}`
  }

  function getRecordLoginUrlWithCode (code) {
    return `${getRecordDomain()}/auth/login?code=${code}`
  }

  function getGameWebsiteUrl (game) {
    const domain = getWebsiteDomain()

    if (game.duelSlug) {
      return `${domain}/seasons/${game.seasonSlug}/leagues/${game.leagueSlug}/playoff/${game.playoffRoundSlug}/${game.duelSlug}/games/${game.round}`
    }

    return `${domain}/seasons/${game.seasonSlug}/leagues/${game.leagueSlug}/rounds/${game.round}/games/${game.slug}`
  }

  function getGameBroadcastWebsiteUrl (game) {
    return `${getGameWebsiteUrl(game)}/broadcast`
  }

  function getNewsItemWebsiteUrl (newsItem) {
    const domain = getWebsiteDomain()

    return `${domain}/news/${newsItem.slug}`
  }

  return {
    getWebsiteDomain,
    getRecordDomain,
    getRecordLoginUrlWithCode,
    getGameWebsiteUrl,
    getGameBroadcastWebsiteUrl,
    getNewsItemWebsiteUrl
  }
}
