import Index from '@/views/coaches/Index'
import Create from '@/views/coaches/Create'
import Edit from '@/views/coaches/Edit'

export default [
  {
    path: ':clientId/coaches',
    name: 'coaches.index',
    component: Index
  },
  {
    path: ':clientId/coaches/create',
    name: 'coaches.create',
    component: Create,
    meta: {
      title: 'Create coach',
      breadcrumb: [
        {
          name: 'Coaches',
          route: {
            name: 'coaches.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: ':clientId/coaches/:uuid',
    name: 'coaches.edit',
    component: Edit,
    meta: {
      title: 'Edit coach',
      breadcrumb: [
        {
          name: 'Coaches',
          route: {
            name: 'coaches.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]
