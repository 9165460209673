import basicMixin from './basic.index.mixin'
const seasonIndexMixin = {
  mixins: [basicMixin],
  watch: {
    currentSeason: {
      handler: function () {
        this.items = []
        this.fetchData()
      },
      immediate: true
    }
  },
  computed: {
    currentSeason () {
      return this.$store.state.user.currentSeason
    }
  }
}

export default seasonIndexMixin
