<template>
  <div v-if="model.schedule">
    <page-header :title="$tc('playoff.name', 1)">
      <li class="breadcrumb-item"><router-link :to="{name:'schedules.index'}">{{$tc('schedule.name', 2)}}</router-link></li>
      <li class="breadcrumb-item"><router-link :to="{name:'schedules.show', params: { uuid: model.schedule.uuid }}">{{model.schedule.name}}</router-link></li>
      <li class="breadcrumb-item">{{$tc('playoff.name', 1)}}</li>
    </page-header>
    <page-content>
      <div class="tournament-bracket tournament-bracket--rounded">
        <div class="tournament-bracket__round tournament-bracket__round--quarterfinals"
             v-for="(round, n) in model.rounds" :key="n">
          <h3 class="tournament-bracket__round-title">
            {{getNameOfRound(round.round)}} {{round.round}}
          </h3>
          <div class="d-flex justify-content-center">
            <div class="border border-info p-2 rounded">
              <h4 class="tournament-bracket__round-subtitle">{{$t('game.number')}} - <strong style="font-size: 1rem">{{ round.bestOf }}</strong></h4>
              <div class="d-flex align-items-center justify-content-center">
                <button class="btn btn-danger btn-sm tournament-bracket__round-add-games-button" @click="deleteGamesToRound(round)"><i class="fa fa-minus"></i></button>
                <button class="btn btn-info btn-sm tournament-bracket__round-add-games-button" @click="addGamesToRound(round)"><i class="fa fa-plus"></i></button>
              </div>
            </div>
          </div>

          <ul class="tournament-bracket__list">
            <li v-for="(duel, i) in round.duels" :key="duel.uuid" class="tournament-bracket__item">
              <div class="tournament-bracket__match" tabindex="0">

                <div class="tournament-bracket__duel">
                  <div class="tournament-bracket__team text-right w-100" v-if="duel.teamA">
                    <div class="selected-team">
                      <span>{{duel.teamA ? teamName(duel.teamA) : 'Team A'}}</span>
                      <button v-if="duel.isEditable" @click="removeTeam(n, i, 'teamA')">x</button>
                    </div>
                  </div>
                  <div class="tournament-bracket__team text-right w-100" v-else>
                    <multiselect :multiple="false"
                                 @select="selectedOption => saveTeam(n, i, 'teamA', selectedOption)"
                                 :custom-label="customLabel"
                                 placeholder="Select team"
                                 v-model="model.rounds[n].duels[i].teamA"
                                 :options="model.options"></multiselect>

                  </div>
                  <div class="tournament-bracket__team text-left w-100" v-if="duel.teamB">
                    <div class="selected-team">
                      <span>{{duel.teamB ? teamName(duel.teamB) : 'Team B'}}</span>
                      <button v-if="duel.isEditable" @click="removeTeam(n, i, 'teamB')">x</button>
                    </div>
                  </div>
                  <div class="tournament-bracket__team text-right w-100" v-else>
                    <multiselect :multiple="false"
                                 @select="selectedOption => saveTeam(n, i, 'teamB', selectedOption)"
                                 :custom-label="customLabel"
                                 placeholder="Select team"
                                 v-model="model.rounds[n].duels[i].teamB"
                                 :options="model.options"></multiselect>

                  </div>
                </div>
                <div class="tournament-bracket__actions">
                  <button @click="viewMatchInfo(duel)" type="button" class="btn btn-primary btn-sm" style="margin-bottom: 5px;"><i class="fa fa-fw fa-eye"></i></button>
                  <button v-if="(model.rounds[n].duels[i].teamA || model.rounds[n].duels[i].teamB) && !duel.winner" @click="resolveDuel(duel)" type="button" class="btn btn-primary btn-sm"><i :class="{'fa-trophy': round.round < 3, 'fa-arrow-right': round.round > 2}" class="fa fa-fw "></i></button>
                </div>
<!--                <div class="tournament-bracket__duel">-->
<!--                  <div class="tournament-bracket__team text-right w-100">4</div>-->
<!--                  <div class="tournament-bracket__team text-center px-2">:</div>-->
<!--                  <div class="tournament-bracket__team text-left w-100">1</div>-->
<!--                </div>-->
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="playoff-actions text-center btn-group">
        <button v-if="!model.is_published" @click="startPlayoff" class="btn btn-success">{{ $t('playoff.publish') }}</button>
        <button @click="deletePlayoff" class="btn btn-danger">{{ $t('playoff.delete') }}</button>
      </div>
      <ntm-modal size="lg" title="Match info" ref="resolveDuel">
        <template slot="content">
          Team1 : Team2
        </template>
      </ntm-modal>
      <ntm-modal size="lg" title="Match info" ref="matchInfo">
        <template slot="content">
          <div v-for="game in duelData.games" :key="game.uuid" class="block p-3 bg-gray-light">
            <div class="row">
              <div class="col-md-5 ml-auto">
                <div class="d-flex justify-content-end">
                  <h3 class="mb-0" v-if="game.teamA"><span v-if="game.teamA.uuid === game.winner" class="badge badge-success">Win</span> {{game.teamA ? game.teamA.name : 'Empty'}}</h3>
                  <h3 v-else class="text-gray-dark ml-4 mb-0">Empty</h3>
                  <h3 class="text-gray-dark ml-4 mb-0">{{game.home_team_score_total}}</h3>
                </div>
              </div>
              <div class="col-md-1 text-center">
                <h3 class="mb-0">:</h3>
              </div>
              <div class="col-md-5 mr-auto">
                <div class="d-flex justify-content-start">
                  <h3 class="text-gray-dark mr-4 mb-0" >{{game.away_team_score_total}}</h3>
                  <h3 class="mb-0" v-if="game.teamB">{{game.teamB ? game.teamB.name : 'Empty'}} <span v-if="game.teamB.uuid === game.winner" class="badge badge-success">Win</span></h3>
                  <h3 v-else class="text-gray-dark ml-4 mb-0">Empty</h3>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center pt-3" v-if="game.teamA && game.teamB">
              <router-link
                :to="{ name: 'schedules.games.show', params: {uuid: $route.params.uuid, game: game.uuid }}"
                class="btn btn-sm btn-primary mx-1">
                <i class="fa fa-pencil"></i>
              </router-link>
            </div>
          </div>
        </template>

      </ntm-modal>
    </page-content>
  </div>
</template>
<script>
import ScheduleService from '../../../services/schedule.service'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Playoff',
  components: {
    Multiselect
  },
  data () {
    return {
      model: {},
      duelData: {},
      resolveDuelData: {}
    }
  },
  mounted () {
    this.$store.commit('loading')
    this.fetchData()
  },
  methods: {
    addGamesToRound (round) {
      ScheduleService.addGameToRoundDuels(this.$route.params.uuid, round.uuid).then((res) => {
        this.fetchData()
      })
    },
    deleteGamesToRound (round) {
      ScheduleService.deleteGameToRoundDuels(this.$route.params.uuid, round.uuid).then((res) => {
        this.fetchData()
      }).catch((err) => {
        console.log(err)
      })
    },
    fetchData () {
      ScheduleService.getPlayoff(this.$route.params.uuid).then((res) => {
        this.model = res.data
        this.$store.commit('loaded')
      })
    },
    customLabel (o) {
      return o.name
    },
    teamName (team) {
      return team.name
    },
    saveTeam (n, i, team, selectedTeam) {
      const t = this
      setTimeout(() => {
        ScheduleService.setDuelTeam(this.$route.params.uuid, this.model.uuid, t.model.rounds[n].duels[i].uuid, {
          slot: team,
          team: selectedTeam
        }).then((res) => {
          this.$store.commit('loaded')
        })
      })
    },
    removeTeam (n, i, team) {
      const t = this
      setTimeout(() => {
        ScheduleService.removeDuelTeam(this.$route.params.uuid, this.model.uuid, t.model.rounds[n].duels[i].uuid, team).then((res) => {
          this.fetchData()
        })
      })
    },
    startPlayoff () {
      ScheduleService.startPlayoff(this.$route.params.uuid).then((res) => {
        this.$store.commit('loaded')
        this.model.is_published = true
      })
    },
    deletePlayoff () {
      ScheduleService.deletePlayoff(this.$route.params.uuid).then((res) => {
        this.$router.push({ name: 'schedules.show', params: { uuid: this.$route.params.uuid } })
      })
    },
    viewMatchInfo (duel) {
      ScheduleService.getDuelGames(this.$route.params.uuid, this.model.uuid, duel.uuid).then((res) => {
        this.duelData = res.data
        this.$refs.matchInfo.open()
      })
    },
    getNameOfRound (round) {
      switch (round) {
        case 8:
          return this.$t('playoff.round.8')
        case 4:
          return this.$t('playoff.round.4')
        case 2:
          return this.$t('playoff.round.2')
        case 1:
          return this.$t('playoff.round.1')
        default:
          return this.$t('playoff.round.bestOf', { round: round })
      }
    },
    async resolveDuel (duel) {
      if (duel.teamA && duel.teamB) {
        const inputOptions = new Promise((resolve) => {
          resolve({
            [duel.teamA.uuid]: duel.teamA.name,
            [duel.teamB.uuid]: duel.teamB.name
          })
        })

        const { value: teamUuid } = await this.$swal.fire({
          title: 'Select duel winner',
          input: 'radio',
          showLoaderOnConfirm: true,
          inputOptions: inputOptions,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to choose something!'
            }
          }
        })
        if (teamUuid) {
          this.setDuelEndpoint(duel.uuid, teamUuid)
        }
      } else {
        duel.teamA ? this.setDuelEndpoint(duel.uuid, duel.teamA.uuid) : this.setDuelEndpoint(duel.uuid, duel.teamB.uuid)
      }
    },
    setDuelEndpoint (duelUuid, teamUuid) {
      ScheduleService.setDuelWinner(this.$route.params.uuid, this.model.uuid, duelUuid, {
        winnerUuid: teamUuid
      }).then((res) => {
        this.$toast.fire({
          title: 'Winner is selected',
          icon: 'success'
        })
        this.fetchData()
      })
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">

  .tournament-bracket {

    &__match {
      position: relative;
      display: flex;
      align-items: center;
    }

    &__duel {
      flex-direction: column;
      margin: -5px 0;
    }

    &__team {
      margin: 5px 0;
    }

    &__actions {
      margin-left: 15px;
    }
  }

  .selected-team {
    background-color: #f5f5f5;
    min-height: 40px;
    padding: 0 8px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      line-height: 1;
    }

    button {
      border: 0;
      width: 18px;
      height: 18px;
      padding: 0;
      font: {
        weight: 600;
        size: 12px
      }
      border-radius: 50%;
      color: #fff;
      background-color: #ed4337;
      cursor: pointer;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }

  .multiselect__option:after {
    display: none;
  }
</style>
