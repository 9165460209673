<template>
    <aside class="aside-form font-size-sm" :class="{open: param}">
      <!-- Side Header -->
      <div class="content-header border-bottom">
        <!-- User Avatar -->
        <a v-if="avatar" class="img-link mr-1" href="javascript:void(0)">
          <img class="img-avatar img-avatar32" :src="avatar" alt="">
        </a>
        <!-- END User Avatar -->

        <!-- User Info -->
        <div class="ml-2">
          <a class="link-fx text-dark font-w600" href="javascript:void(0)">{{ title }}</a>
        </div>
        <!-- END User Info -->
        <div class="btn-group">

        <slot name="options"></slot>
        <!-- Close Side Overlay -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
        <a class="btn btn-sm btn-dual bg-danger border-danger" href="javascript:void(0)" @click="param = null">
          <i class="fa fa-fw fa-times text-white"></i>
        </a>
        </div>
        <!-- END Close Side Overlay -->
      </div>
      <!-- END Side Header -->

      <!-- Side Content -->
      <div class="content-side" :id="contentElementId">
        <slot></slot>
        <div :id="infiniteLoadElementId"/>
      </div>
      <!-- END Side Content -->
    </aside>
</template>
<script>
import { useRouteQuery } from '../../use/useRouteQuery'
import { watch } from 'vue'

export default {
  name: 'ntmSidebar',
  props: {
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    avatar: {
      type: String,
      default: null
    }
  },
  computed: {
    infiniteLoadElementId () {
      return `infinite-${this._uid}`
    },
    contentElementId () {
      return `sidebar-content-${this._uid}`
    }
  },
  setup (props, { emit }) {
    const param = useRouteQuery(props.name)

    function close () {
      param.value = null
      emit('close')
    }

    watch(param, (value) => {
      if (value) {
        emit('opened')
      }
    }, { immediate: true })

    return {
      param,
      close
    }
  },
  mounted () {
    const element = document.getElementById(this.contentElementId)
    if (!element) {
      return
    }
    element.addEventListener('scroll', this.infiniteLoad)
  },
  destroyed () {
    const element = document.getElementById(this.contentElementId)
    if (!element) {
      return
    }
    element.removeEventListener('scroll', this.infiniteLoad)
  },
  methods: {
    infiniteLoad (event) {
      const element = document.getElementById(this.infiniteLoadElementId)
      if (element.getBoundingClientRect().top < window.innerHeight) {
        this.$emit('loadMore')
      }
    }
  }
}
</script>
<style>
.aside-form {
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  overflow-y: auto;
  -webkit-transform: translateX(100%) translateY(0) translateZ(0);
  transform: translateX(100%) translateY(0) translateZ(0);
  transition: 0.5s ease;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  box-shadow: 10px 0px 15px 5px #000;
  will-change: transform;
  width: 400px;
}

.aside-form.open {
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}

.aside-form .content-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (max-width: 400px) {
  .aside-form {
    width: 100%;
  }
}
</style>
