const fields = [
  {
    key: 'suffix',
    type: 'input',
    templateOptions: {
      label: 'suffix'
    }
  },
  {
    key: 'club',
    type: 'ntm-select',
    optionsApi: 'clubs',
    templateOptions: {
      label: 'club'
    }
  },
  {
    key: 'group',
    type: 'ntm-select',
    options: [],
    templateOptions: {
      label: 'group'
    }
  },
  {
    key: 'out_of_competition',
    type: 'boolean',
    templateOptions: {
      label: 'out_of_competition'
    }
  },
  {
    key: 'head_coach',
    type: 'ntm-select',
    optionsApi: 'coaches',
    templateOptions: {
      label: 'head_coach'
    }
  },
  {
    key: 'assistant_coach',
    type: 'ntm-select',
    optionsApi: 'coaches',
    templateOptions: {
      label: 'assistant_coach'
    }
  }
]

export default fields
