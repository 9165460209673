import Index from '@/views/news/Index'
import Create from '@/views/news/Create'
import Edit from '@/views/news/Edit'

export default [
  {
    path: ':clientId/news',
    name: 'news.index',
    component: Index
  },
  {
    path: ':clientId/news/create',
    name: 'news.create',
    component: Create,
    meta: {
      title: 'Create news',
      breadcrumb: [
        {
          name: 'News',
          route: {
            name: 'news.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: ':clientId/news/:uuid',
    name: 'news.edit',
    component: Edit,
    meta: {
      title: 'Edit news',
      breadcrumb: [
        {
          name: 'News',
          route: {
            name: 'news.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]
