import Index from '@/views/settings/Index'
import FacebookIndex from '@/views/facebook/Index'
import ImportIndex from '@/views/import/Index'

export default [
  {
    path: ':clientId/settings/website',
    name: 'settings.index',
    component: Index
  },
  {
    path: ':clientId/settings/facebook',
    name: 'facebook.index',
    component: FacebookIndex
  },
  {
    path: ':clientId/settings/import',
    name: 'import.index',
    component: ImportIndex
  }
]
