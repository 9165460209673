<template>
  <div class="activity_index">
    <page-header :title="$tc('activity.name', 2)">
    </page-header>
    <page-content>
      <ntm-block :collapsable="false">
        <div class="row">
          <div class="col-sm-8">
            <form @submit.prevent="fetchData(true)">
              <formly-form :model="searchModel" :fields="fields" :form="form" @submit="fetchData"></formly-form>
              <button class="btn btn-success push-15">{{$t('search')}}</button>
            </form>
          </div>
          <div class="col-sm-4">
            <div v-for="(activity, a) in model.data" :key="a">
              <activity-log :activity="activity"></activity-log>
            </div>
            <button v-if="!stopLazyLoading" class="btn btn-info mb-4 w-100" type="button" @click="loadMore()"><i class="fa fa-arrow-down"></i></button>
          </div>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>
<script>
import ActivityService from '@/services/activity.service'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import { useExternalLinks } from '@/use/useExternalLinks'
import ActivityLog from '@/components/shared/ActivityLog.vue'

export default {
  components: { ActivityLog },
  mixins: [basicIndexMixin],
  setup () {
    const { getWebsiteDomain } = useExternalLinks()

    return {
      getWebsiteDomain
    }
  },
  data () {
    return {
      stopLazyLoading: false,
      searchModel: {
        page: 1,
        fromDate: null,
        toDate: null,
        entityType: null
      },
      form: {},
      fields: [
        {
          key: 'entityType',
          type: 'ntm-select',
          options: [],
          templateOptions: {
            label: 'entity'
          }
        },
        {
          key: 'fromDate',
          type: 'datepicker',
          format: 'YYYY-MM-DD',
          templateOptions: {
            label: 'fromDate',
            onlyDate: true,
            autoClose: true
          }
        },
        {
          key: 'toDate',
          type: 'datepicker',
          format: 'YYYY-MM-DD',
          templateOptions: {
            label: 'toDate',
            onlyDate: true,
            autoClose: true
          }
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    ActivityService.index({ page: 1 }).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    fetchData (reset = false) {
      if (reset) {
        this.searchModel.page = 1
        this.stopLazyLoading = true
        this.model.data = []
      }

      ActivityService.index(this.searchModel).then((response) => {
        this.model.data.push(...response.data.data)
        if (response.data.data.length < 15) {
          this.stopLazyLoading = true
        } else {
          this.stopLazyLoading = false
        }
      })
    },
    loadMore () {
      this.searchModel.page++
      this.fetchData()
    }
  },
  mounted () {
    const entityField = this.fields.find((field) => field.key === 'entityType')
    entityField.options = this.entities
  },
  computed: {
    entities () {
      return [
        { uuid: 'Game', name: this.$tc('game.name', 1) },
        { uuid: 'Team', name: this.$tc('team.name', 1) },
        { uuid: 'Schedule', name: this.$tc('schedule.name', 1) },
        { uuid: 'Player', name: this.$tc('player.name', 1) },
        { uuid: 'Coach', name: this.$tc('coach.name', 1) },
        { uuid: 'Club', name: this.$tc('club.name', 1) },
        { uuid: 'Venue', name: this.$tc('venue.name', 1) },
        { uuid: 'League', name: this.$tc('league.name', 1) },
        { uuid: 'Season', name: this.$tc('season.name', 1) },
        { uuid: 'Season', name: this.$tc('season.name', 1) },
        { uuid: 'Document', name: this.$tc('document.name', 1) }
      ]
    }
  }
}
</script>
