export default [
  {
    key: 'entity',
    trackBy: 'id',
    type: 'ntm-select',
    options: [
    ],
    templateOptions: {
      placeholder: 'Select entity'
    }
  },
  {
    key: 'isAutoGenerateGameEvent',
    type: 'boolean',
    templateOptions: {
      label: 'isAutoGenerateGameEvent'
    }
  }
]
